import React, { Fragment } from "react";
import $ from "jquery";
import SecureComponent from "./SecureComponent";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { showQuestionPopUp } from "../common";
import Partners from "./Partners";
import Subscriptions from "./Subscriptions";
import Carousel from "./Carousel";
import Messages from "./Messages";
import Publishings from "./Publishings";

export default class Admin extends SecureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: "partners"
    };
  }

  renderLogoutView() {
    const min = 1;
    const max = 32;
    const random = Math.floor(Math.random() * (+max - +min)) + +min;
    return (
      <div className="container pingui-container">
        <div className="center-pingui">
          <img
            src={`/images/pingui-${random}.png`}
            alt={`Pingui ${random}`}
            className="pingui-logo"
          />
          <br />
          <br />
          <button
            className="text-uppercase admin-button"
            onClick={this.handleLogoutClick}
          >
            <i className="fa fa-sign-out" />
            Cerrar sesión
          </button>
        </div>
      </div>
    );
  }

  handleLogoutClick = e => {
    showQuestionPopUp(
      "Cerrar sesión",
      "¿Desea salir del módulo de administración?",
      null,
      "admin",
      this.logout
    );
  };

  render() {
    if (this.isAuthenticated()) {
      return (
        <Fragment>
          <SideNav
            onSelect={selected => {
              if (this.state.selected !== selected) {
                this.setState({ selected: selected });
              }
            }}
            onToggle={toggle => {
              $(".admin_section").css(
                "padding-left",
                toggle ? "190px" : "64px"
              );
            }}
          >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected={this.state.selected}>
              <NavItem eventKey="partners">
                <NavIcon>
                  <i className="fa fa-fw fa-users nav-icon" />
                </NavIcon>
                <NavText>Socios</NavText>
              </NavItem>
              <NavItem eventKey="publishings">
                <NavIcon>
                  <i className="fa fa-fw fa-newspaper-o nav-icon" />
                </NavIcon>
                <NavText>Publicaciones</NavText>
              </NavItem>
              <NavItem eventKey="newsletter">
                <NavIcon>
                  <i className="fa fa-fw fa-location-arrow nav-icon" />
                </NavIcon>
                <NavText>Suscripciones</NavText>
              </NavItem>
              <NavItem eventKey="messages">
                <NavIcon>
                  <i className="fa fa-fw fa-envelope-o nav-icon" />
                </NavIcon>
                <NavText>Mensajes</NavText>
              </NavItem>
              <NavItem eventKey="carousel">
                <NavIcon>
                  <i className="fa fa-fw fa-picture-o nav-icon" />
                </NavIcon>
                <NavText>Galeria</NavText>
              </NavItem>
              <NavItem eventKey="logout">
                <NavIcon>
                  <i className="fa fa-fw fa-sign-out nav-icon nav-icon-sign-out" />
                </NavIcon>
                <NavText>Cerrar sesión</NavText>
              </NavItem>
            </SideNav.Nav>
          </SideNav>
          <section className="admin_section section_gap">
            <div>
              {this.state.selected && this.state.selected === "partners" && (
                <Partners />
              )}
              {this.state.selected && this.state.selected === "newsletter" && (
                <Subscriptions />
              )}
              {this.state.selected && this.state.selected === "carousel" && (
                <Carousel />
              )}
              {this.state.selected && this.state.selected === "messages" && (
                <Messages />
              )}
              {this.state.selected && this.state.selected === "publishings" && (
                <Publishings />
              )}
              {this.state.selected &&
                this.state.selected === "logout" &&
                this.renderLogoutView()}
            </div>
          </section>
        </Fragment>
      );
    } else return this.renderLogin();
  }
}
