import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PartnersProfileDetailEditor from "./PartnersProfileDetailEditor";
import { server } from "../../apis";
import { AgGridReact } from "ag-grid-react";
import GridBase from "../common/GridBase";
import $ from "jquery";

const _swalPopUp = withReactContent(Swal);

export default class PartnersDetailGrid extends GridBase {
  constructor(props) {
    super(props);
    this.refreshGrids = this.props.refreshGrids.bind(this);
  }

  handleNewDetailClick = async e => {
    this.openPopUpEditor({
      profileId: this.props.getSelectedId()
    });
  };

  openPopUpEditor = data => {
    $.extend(
      true,
      {
        id: "",
        profileId: "",
        title: "",
        content: "",
        number: ""
      },
      data
    );
    //alert(JSON.stringify(data));
    let partnersProfileDetailEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 1200,
        html: (
          <div className="html-editor-popup">
            <PartnersProfileDetailEditor
              ref={ref => {
                partnersProfileDetailEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = partnersProfileDetailEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          //alert(JSON.stringify(data));
          let response = await server.post.profileDetail(data);
          if (response.status === 200) {
            this.refreshGrids();
          }
        }
      });
  };

  render() {
    return (
      <div className="ag-theme-blue ag-grid-container">
        <div
          style={{
            marginBottom: "5px",
            maxHeight: "40px",
            minHeight: "40px",
            height: "40px"
          }}
        >
          <button
            className="text-uppercase admin-button"
            onClick={this.handleNewDetailClick}
          >
            <i className="fa fa-user" />
            Nuevo detalle
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.refresh}
          >
            <i className="fa fa-refresh" /> Actualizar
          </button>
        </div>
        <div style={{ height: "calc(60vh - 50px)" }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            getRowNodeId={data => data.id}
            modules={this.state.modules}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            statusBar={this.state.statusBar}
            paginationPageSize={this.state.paginationPageSize}
            enableRangeSelection={false}
            enableCharts={false}
            localeText={this.state.localeText}
            onGridReady={this.onGridReady}
          />
        </div>
      </div>
    );
  }
}
