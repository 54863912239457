import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { server } from "../../apis";
import { showPopUp, showGoogleMapPopUp } from "../common";
import $ from "jquery";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscription = this.subscription.bind(this);
    this.callPhone = this.callPhone.bind(this);
    this.openMapPopUp = this.openMapPopUp.bind(this);
  }

  componentDidMount() {
    $("#menu-footer-" + this.props.index).addClass("hide");
  }

  openMapPopUp(e) {
    e.preventDefault();
    showGoogleMapPopUp(e.target.getAttribute("data-src"));
  }

  callPhone(e) {
    e.preventDefault();
    window.location.href = "tel://" + e.target.getAttribute("data-phone");
  }

  async subscription(values, resetForm) {
    let email = document.getElementById("emailNewsletter").value;
    $("#btnNewsletter").attr("disabled", true);
    let response = await server.post.subscription({
      email: email,
    });
    //console.log(JSON.stringify(response));
    if (response.status === 201) {
      showPopUp(
        "Suscripción a publicaciones",
        "Se ha suscrito a la notificación de nuevas publicaciones.<br/>Le enviamos un email a <strong>'" +
          email +
          "'</strong>,<br/>por favor confirme la suscripción."
      );
      resetForm();
    }
    if (response.status === 422) {
      showPopUp(
        "Suscripción a publicaciones",
        "Ya se encuentra suscrito a la notificación de nuevas publicaciones.<br/>Le enviamos un email a <strong>'" +
          email +
          "'</strong>,<br/>por favor confirme la suscripción.<br/>Verifique en el buzón de correo no deseado."
      );
      resetForm();
    }
    if (response.status === 400) {
      showPopUp(
        "Suscripción a publicaciones",
        "¡Usted tiene una suscripción anterior que fue revocada!.<br/>En este caso para volver a suscribirse debe enviar un mail solicitando la suscripción.'"
      );
      resetForm();
    }
    $("#btnNewsletter").attr("disabled", false);
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer_top section_gap_top">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="single-footer-widget">
                    <h5 className="footer_title">Contacto</h5>
                    <div className="contact_info">
                      <div className="info_item">
                        <i className="lnr lnr-home"></i>
                        <h6>
                          <a
                            data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0123553231397!2d-58.388541084770395!3d-34.603849080459476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac682455555%3A0x586b8b57b68a1968!2sAv.%20Corrientes%201393%2C%20C1043%20CABA!5e0!3m2!1ses-419!2sar!4v1575592471986!5m2!1ses-419!2sar"
                            href="#"
                            onClick={this.openMapPopUp}
                          >
                            Av. Corrientes 1393 3° F
                          </a>
                        </h6>
                        <p>(C1043) CABA, Buenos Aires - Argentina</p>
                      </div>
                      <div className="info_item">
                        <i className="lnr lnr-home"></i>
                        <h6>
                          <a
                            data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.498719274528!2d-58.56742068491858!3d-34.64210598044947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc7e57add2f0f%3A0xef7eb7b9854a4ded!2sAv.%20de%20Mayo%20136%2C%20B1704BUO%20La%20Matanza%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1575593107921!5m2!1ses-419!2sar"
                            href="#"
                            onClick={this.openMapPopUp}
                          >
                            Belgrano 181 3° A
                          </a>
                        </h6>
                        <p>(B1704BUO) Ramos Mejía, Buenos Aires - Argentina</p>
                      </div>
                      <div className="info_item">
                        <i className="lnr lnr-phone"></i>
                        <h6>
                          <a
                            className="phonecell"
                            data-phone="+5491134308533"
                            href="#"
                            onClick={this.callPhone}
                          >
                            (+54) 9 11 3430-8533
                          </a>
                        </h6>
                      </div>
                      <div className="info_item">
                        <i className="lnr lnr-phone"></i>
                        <h6>
                          <a
                            className="phonecell"
                            data-phone="+5491153388220"
                            href="#"
                            onClick={this.callPhone}
                          >
                            (+54) 9 11 5338-8220
                          </a>
                        </h6>
                      </div>
                      <div className="info_item">
                        <i className="lnr lnr-phone"></i>
                        <h6>
                          <a
                            className="phonecell"
                            data-phone="+5491148283899"
                            href="#"
                            onClick={this.callPhone}
                          >
                            (+54) 011 4828-3899
                          </a>
                        </h6>
                        <p>Lunes a viernes de 9hs a 18hs</p>
                      </div>
                      <div className="info_item">
                        <i className="lnr lnr-envelope"></i>
                        <h6>
                          <a
                            href="mailto:contacto@estudiopirozzobuigo.com.ar"
                            className="mailto-footer"
                          >
                            contacto@estudiopirozzobuigo.com.ar
                          </a>
                        </h6>
                        <p>Su pregunta no molesta.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6">
                  <div className="single-footer-widget">
                    <h5 className="footer_title">Páginas</h5>
                    <div className="row">
                      <div className="col-5">
                        <ul className="list">
                          <li id="menu-footer-1">
                            <a href="/home">Inicio</a>
                          </li>
                          <li id="menu-footer-2">
                            <a href="/about">Historia</a>
                          </li>
                          <li id="menu-footer-4">
                            <a href="/team">Nosotros</a>
                          </li>
                          <li id="menu-footer-3">
                            <a href="/services">Servicios</a>
                          </li>
                          <li id="menu-footer-6">
                            <a href="/contact">Contacto</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="single-footer-widget">
                    <h5 className="footer_title">Novedades</h5>
                    <p>
                      Ingrese su e-mail y reciba avisos de nuevas publicaciones
                      de nuestro sitio, podrá desasociarse en cualquier momento.
                    </p>
                    <div className="footer-newsletter" id="mc_embed_signup">
                      <Formik
                        initialValues={{
                          emailNewsletter: "",
                        }}
                        validate={(values) => {
                          let errors = {};
                          let regMail = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
                          if (!regMail.test(values.emailNewsletter.trim())) {
                            errors.emailNewsletter = "El e-mail no es válido";
                          }
                          return errors;
                        }}
                        onSubmit={(values, { resetForm }) => {
                          this.subscription(values, resetForm);
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form className="form-inline">
                            <div className="d-flex flex-row">
                              <Field
                                className="form-control"
                                id="emailNewsletter"
                                name="emailNewsletter"
                                type="emailNewsletter"
                                placeholder="Ingrese su e-mail"
                                onFocus={(e) => (e.target.placeholder = "")}
                                onBlur={(e) =>
                                  (e.target.placeholder = "Ingrese su e-mail")
                                }
                                required=""
                              />
                              <button
                                className="click-btn btn btn-default"
                                id="btnNewsletter"
                              >
                                <span
                                  className="lnr lnr-location"
                                  aria-hidden="true"
                                ></span>
                              </button>
                            </div>
                            <div className="error-mail-footer">
                              {errors.emailNewsletter &&
                                touched.emailNewsletter && (
                                  <span className="errors active">
                                    {errors.emailNewsletter}
                                  </span>
                                )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <p>
                    Copyright &copy;
                    {new Date().getFullYear()} Todos los derechos reservados |{" "}
                    <a href="/home" target="_blank">
                      Pirozzo, Buigo & Mezzalira
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
