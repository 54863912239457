import axios from "axios";

export class Response {
  constructor(obj) {
    this.server = {};
    if (obj.response) {
      this.server.status = obj.response.status;
      this.server.data = obj.response.data;
    } else {
      this.server.status = obj.status ? obj.status : null;
      this.server = obj.message ? { message: obj.message } : obj;
    }
  }

  get status() {
    return this.server.status;
  }

  get data() {
    return this.server.data;
  }
}

const ApisCore = config => {
  var createInstance = config => {
    config.auth = {
      username: localStorage.getItem("ui.layout.key"),
      password: localStorage.getItem("ui.layout.skin")
    };
    //console.log(config);
    let axiosInstance = axios.create(config);
    axiosInstance.interceptors.request.use(beforeRequest, onError);
    axiosInstance.interceptors.response.use(onSuccess, onError);
    return axiosInstance;
  };

  var _get = async url => {
    return createInstance(config).get(url);
  };

  var _post = async (url, data) => {
    return createInstance(config).post(url, data);
  };

  var _put = async (url, data) => {
    return createInstance(config).put(url, data);
  };

  var _patch = async (url, data) => {
    return createInstance(config).patch(url, data);
  };

  var _delete = async (url, data) => {
    return createInstance(config).delete(url, data);
  };

  var beforeRequest = request => {
    //const token = localStorage.getItem("security.token");
    //request.headers.Authorization = `Bearer ${token}`;
    return request;
  };

  var onSuccess = response => {
    return new Response(response);
  };

  var onError = error => {
    //console.log(JSON.stringify(error));
    if (error.response) {
      // eslint-disable-next-line no-console
      console.error("response: ", error.response);
      if (
        error.response &&
        error.response.status &&
        (error.response.status === 401 || error.response.status === 403)
      )
        window.location =
          window.location.protocol + "//" + window.location.host + "/login";
      //alert(JSON.stringify(error.response));
    } else if (error.request) {
      // eslint-disable-next-line no-console
      console.error("request: ", error.request);
      //alert(JSON.stringify(error));
    } else {
      // eslint-disable-next-line no-console
      console.error("generic: ", error);
      //alert(JSON.stringify(error));
    }
    //alert(error.message);
    return new Response(error);
  };

  return {
    get: url => {
      return _get(url);
    },
    post: (url, data) => {
      return _post(url, data);
    },
    put: (url, data) => {
      return _put(url, data);
    },
    patch: (url, data) => {
      return _patch(url, data);
    },
    delete: (url, data) => {
      return _delete(url, data);
    }
  };
};

export default class Api {
  constructor(config) {
    this.apisCore = new ApisCore(config);
  }

  get = async url => {
    return this.apisCore.get(url);
  };

  post = async (url, data) => {
    return this.apisCore.post(url, data ? data : {});
  };

  put = async (url, data) => {
    return this.apisCore.put(url, data ? data : {});
  };

  patch = async (url, data) => {
    return this.apisCore.patch(url, data ? data : {});
  };

  delete = async (url, data) => {
    return this.apisCore.delete(url, data ? data : {});
  };
}
