import React, { Component } from "react";
import Menu from "../menu";
import Footer from "../footer";
import HomeBanner from "./banner";
import AboutSection from "../about/section";
import ServicesSection from "../services/section";
import TeamSection from "../team/section";

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Menu index={1} />
        <HomeBanner />
        <AboutSection />
        <ServicesSection />
        <TeamSection />
        <Footer index={1} />
      </React.Fragment>
    );
  }
}
