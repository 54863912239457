import React, { Component } from "react";
import "../../assets/css/accordion.css";
import { Markup } from "interweave";

export default class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = { items: this.props.items };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.itemsRefs = [];
  }

  handleItemClick(id) {
    this.itemsRefs.forEach(function(item, index) {
      if (item.props.id !== id) item.close();
      else item.open();
    });
  }

  render() {
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          {this.state.items.map((item, key) => {
            return (
              <li {...{ className: "accordion-list__item", key }}>
                <AccordionItem
                  {...item}
                  id={`accordion-list__item_${JSON.stringify(key)}`}
                  handleItemClick={this.handleItemClick}
                  ref={ref => {
                    this.itemsRefs.push(ref);
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
    this.handleItemClick = this.props.handleItemClick.bind(this);
  }

  close() {
    this.setState({ opened: false });
  }

  open() {
    this.setState({ opened: true });
  }

  render() {
    const {
      props: { content, title, id },
      state: { opened }
    } = this;
    return (
      <div
        {...{
          id: id,
          className: `accordion-item, ${opened && "accordion-item--opened"}`,
          onClick: () => {
            if (!opened) this.handleItemClick(id);
            else this.close();
          }
        }}
      >
        <div {...{ className: "accordion-item__line" }}>
          <h3 {...{ className: "accordion-item__title" }}>{title}</h3>
          <span {...{ className: "accordion-item__icon" }} />
        </div>
        <div {...{ className: "accordion-item__inner" }}>
          <div {...{ className: "accordion-item__content" }}>
            <Markup content={content} />
          </div>
        </div>
      </div>
    );
  }
}
