import React, { Component, Fragment } from "react";
import Select from "react-select";
import HtmlEditor from "../common/HtmlEditor";
import { showError, hideError, validateInputLength } from "../common";
import $ from "jquery";

const categories = [
  { value: "1", label: "Medios" },
  { value: "2", label: "Doctrina" }
];

export default class PublishingsPreviewEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption:
        this.props.data && this.props.data.categoryId
          ? {
              value: this.props.data.categoryId,
              label: this.props.data.category
            }
          : null,
      data: {
        id: "",
        categoryId: "",
        title: "",
        author: "",
        signature: "",
        date: "",
        summary: "",
        content: ""
      }
    };
    $.extend(true, this.state.data, this.props.data);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  extendState = summary => {
    let categoryId = this.state.selectedOption
      ? this.state.selectedOption.value
      : "";
    this.setState({
      data: $.extend(
        true,
        $.extend(true, this.state.data, {
          categoryId: categoryId,
          title: $("#title").val(),
          author: $("#author").val(),
          signature: $("#signature").val(),
          date: $("#date").val(),
          content: ""
        }),
        {
          summary: summary
        }
      )
    });
  };

  handleContentChange = content => {
    this.extendState(content);
    this.validateHtmlEditor();
  };

  validate = () => {
    this.validateForm();
    let isvalid =
      !$("#title").hasClass("not-valid") &&
      this.state.selectedOption &&
      this.state.selectedOption.value &&
      this.state.data.summary.length >= 100;
    if (isvalid) {
      this.extendState(this.state.data.summary);
      return this.state.data;
    }
    this.validateHtmlEditor();
    return false;
  };

  validateHtmlEditor = () => {
    let message = "Ingrese una texto más largo, para la publicación.";
    let editor = $("div.note-editor.note-frame.panel.panel-default");
    let div = $(editor).next();
    if (this.state.data.summary.length >= 100) {
      if (div !== null && div.hasClass("errors-container")) div.remove();
    } else {
      if (div == null || !div.hasClass("errors-container")) {
        div = $("<div/>");
        div.addClass("errors-container");
        let span = $("<span/>");
        span.html(message).addClass("errors active");
        div.append(span);
        editor.parent().append(div);
      } else if (div !== null) {
        let span = $(div).next();
        if (span.html() !== message) span.html(message);
      }
    }
  };

  validateForm = () => {
    validateInputLength(
      $("#title"),
      "Ingrese un título para la publicación (10 caracteres como mínimo)",
      10
    );
    if (this.state.selectedOption === null) {
      showError(
        $("#selectCategories"),
        "Seleccione la categoria de la publicación."
      );
    }
  };

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
    hideError($("#selectCategories"));
    //console.log(`Option selected:`, selectedOption);
  };

  render() {
    const { selectedOption } = this.state;
    const customStyles = {
      control: base => ({
        ...base,
        minHeight: "fit-content",
        height: "calc(1.5rem + 2px)"
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 2
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      multiValue: base => ({
        ...base,
        backgroundColor: "#eee"
      }),
      valueContainer: base => ({
        ...base,
        padding: "0px 6px"
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0
      })
    };
    return (
      <Fragment>
        <section className="publishings-editor">
          <div className="container">
            <div className="admin_form">
              <form
                className="admin_form"
                id="publishings-editor-form"
                noValidate={true}
              >
                <div className="row">
                  <div className="col-md-12 field-validate">
                    <div className="form-group">
                      <Select
                        id={"selectCategories"}
                        styles={customStyles}
                        value={selectedOption}
                        onChange={this.handleSelectChange}
                        options={categories}
                        placeholder={"Seleccione una categoría"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 field-validate">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control not-valid"
                        id="title"
                        name="title"
                        defaultValue={this.state.data.title}
                        placeholder="Ingrese un título para la publicación"
                        onFocus={e => (e.target.placeholder = "")}
                        onBlur={e =>
                          (e.target.placeholder =
                            "Ingrese un título para la publicación")
                        }
                        onKeyUp={e => {
                          validateInputLength(
                            $(e.target),
                            "Ingrese un título para la publicación (10 caracteres como mínimo)",
                            10
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 field-novalidate">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control not-valid"
                        id="author"
                        name="author"
                        defaultValue={this.state.data.author}
                        placeholder="Ingrese el nombre del autor"
                        onFocus={e => (e.target.placeholder = "")}
                        onBlur={e =>
                          (e.target.placeholder = "Ingrese el nombre del autor")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 field-novalidate">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control not-valid"
                        id="signature"
                        name="signature"
                        defaultValue={this.state.data.signature}
                        placeholder="Ingrese una descripción (ej. la profesión)"
                        onFocus={e => (e.target.placeholder = "")}
                        onBlur={e =>
                          (e.target.placeholder =
                            "Ingrese una descripción (ej. la profesión)")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 field-novalidate">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control not-valid"
                        id="date"
                        name="date"
                        defaultValue={this.state.data.date}
                        placeholder="Ingrese un detalle. (ej. fecha o ISBN)"
                        onFocus={e => (e.target.placeholder = "")}
                        onBlur={e =>
                          (e.target.placeholder =
                            "Ingrese un detalle. (ej. fecha o ISBN)")
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section className="publishings-editor">
          <div
            id="html-editor-container"
            className="container field-html-editor"
          >
            <div className="row">
              <div className="col-lg-12">
                <HtmlEditor
                  handleContentChange={this.handleContentChange}
                  content={this.props.data.summary}
                  height={290}
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
