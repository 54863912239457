import React, { Component, Fragment } from "react";
import PartnersGrid from "./PartnersGrid";
import PartnersDetailGrid from "./PartnersDetailGrid";
import ProfileEditor from "./PartnersProfileEditor";
import PartnersProfileDetailEditor from "./PartnersProfileDetailEditor";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AvatarImageCropper from "react-avatar-image-cropper";
import { server } from "../../apis";
import $ from "jquery";
import { showPopUp, showQuestionPopUp } from "../common";
import SwalPopUp from "../common/SwalPopUp";
import { Markup } from "interweave";
import Accordion from "../common/Accordion";

const _swalPopUp = withReactContent(Swal);
const maxsize = 1024 * 1024 * 5;

export default class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
    this.getGridRowDataPartners = this.getGridRowDataPartners.bind(this);
    this.gridPartners = React.createRef();
    this.gridDetail = React.createRef();
  }

  columnDefsPartner = [
    {
      headerName: "Nombre",
      field: "title"
    },
    {
      headerName: "Especialidad",
      field: "detail",
      width: 300
    },
    {
      headerName: "",
      field: "edit",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleEditPartnerClick}
              title="Editar..."
            >
              <i className="fa fa-pencil ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "delete",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleDeletePartnerClick}
              title="Eliminar..."
            >
              <i className="fa fa-trash ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "avatar1",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.openAvatarCropper}
              title="Cambiar fotografía principal..."
            >
              <i className="fa fa-picture-o ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "avatar2",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.openAvatarCropper}
              title="Cambiar fotografía del detalle..."
            >
              <i className="fa fa-picture-o ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "preview",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handlePreviewPartnerClick}
              title="Vista previa..."
            >
              <i className="fa fa-search ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "ready",
      width: 40,
      filter: false,
      resizable: false,
      cellRendererFramework: params => {
        let className =
          "fa " +
          (params.value === "1"
            ? "fa-thumbs-up fa-green"
            : "fa-thumbs-down fa-red") +
          " ag-grid-icon";
        let title = params.value === "1" ? "En línea..." : "Fuera de línea...";
        return (
          <Fragment>
            <div className="ag-grid-cell-icon" title={title}>
              <i className={className} />
            </div>
          </Fragment>
        );
      }
    }
  ];

  columnDefsDetail = [
    {
      headerName: "Nombre",
      field: "name"
    },
    {
      headerName: "Título",
      field: "title",
      width: 300
    },
    {
      headerName: "Orden",
      field: "number",
      width: 70,
      filter: false,
      resizable: false
    },
    {
      headerName: "",
      field: "edit",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleEditDetailClick}
              title="Editar..."
            >
              <i className="fa fa-pencil ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "delete",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleDeleteDetailClick}
              title="Eliminar..."
            >
              <i className="fa fa-trash ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "ready",
      width: 40,
      filter: false,
      resizable: false,
      cellRendererFramework: params => {
        let className =
          "fa " +
          (params.value === "1"
            ? "fa-thumbs-up fa-green"
            : "fa-thumbs-down fa-red") +
          " ag-grid-icon";
        let title = params.value === "1" ? "En línea..." : "Fuera de línea...";
        return (
          <Fragment>
            <div className="ag-grid-cell-icon" title={title}>
              <i className={className} />
            </div>
          </Fragment>
        );
      }
    }
  ];

  getGridRowDataPartners = async data => {
    let response = await server.get.profileEditData(data);
    if (response.status === 200) {
      this.openPopUpEditorPartners(response.data);
    }
  };

  getDataSourcePartners = async () => {
    let response = await server.get.profiles();
    if (response.status === 200) {
      return response.data;
    }
    return [];
  };

  getGridRowDataDetail = async data => {
    let response = await server.get.profileDetailData(data);
    if (response.status === 200) {
      this.openPopUpEditorDetail(response.data);
    }
  };

  getDataSourceDetail = async () => {
    if (this.state.selected) {
      let response = await server.get.profileDetailData({
        profile: this.state.selected
      });
      if (response.status === 200) {
        return response.data;
      }
    }
    return [];
  };

  openPopUpEditorDetail = data => {
    $.extend(
      true,
      {
        id: "",
        profileId: "",
        title: "",
        content: "",
        number: ""
      },
      data
    );
    //alert(JSON.stringify(data));
    let profileDetailEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 1200,
        html: (
          <div className="html-editor-popup">
            <PartnersProfileDetailEditor
              ref={ref => {
                profileDetailEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = profileDetailEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          let response = await server.put.profileDetail(data);
          if (response.status === 200) {
            await this.gridDetail.current.refresh();
            await this.gridPartners.current.refresh();
          }
        }
      });
  };

  openPopUpEditorPartners = data => {
    $.extend(
      true,
      {
        id: "",
        title: "",
        detail: "",
        content: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: ""
      },
      data
    );
    //alert(JSON.stringify(data));
    let profileEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 1200,
        html: (
          <div className="html-editor-popup">
            <ProfileEditor
              ref={ref => {
                profileEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = profileEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          this.setState({
            data: data
          });
          ///alert(JSON.stringify(data));
          let response = await server.put.profile(data);
          if (response.status === 200) {
            this.gridPartners.current.updateRow(data);
          }
        }
      });
  };

  openAvatarCropper = e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    let avatar = $(e.target)
      .parents(".ag-cell:first")
      .attr("col-id");
    this.setState({
      profile: id,
      avatar: avatar === "avatar1" ? 1 : 2
    });
    let _apply =
      avatar === "avatar1"
        ? this.applyAvatarPrimary.bind(this)
        : this.applyAvatarSecondary.bind(this);
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 400,
        html: (
          <div className="avatar-cropper-popup">
            <div
              style={{
                backgroundImage: 'url("/images/avatar-bk.png")',
                width: "352px",
                height: "352px",
                margin: "auto",
                marginTop: "10px",
                border: "1px solid #FFF",
                borderRadius: "5px"
              }}
            >
              <AvatarImageCropper
                apply={_apply}
                isBack={true}
                maxsize={maxsize}
                text={"Arrastrar o hacer click aquí."}
                errorHandler={this.avatarErrorHandler}
                loadingNode={<div style={{ color: "#FFF" }}>Cargando...</div>}
              />
            </div>
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: false,
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
        }
      });
  };

  applyAvatarPrimary = async blop => {
    await this.avatarUpload(blop);
  };

  applyAvatarSecondary = async blop => {
    await this.avatarUpload(blop);
  };

  avatarErrorHandler = type => {
    _swalPopUp.close();
    showPopUp(
      "Ups..!",
      "Ocurrió un error al procesar la imagen.",
      null,
      "admin"
    );
  };

  avatarUpload = async blop => {
    let avatar = this.state.avatar;
    let profile = this.state.profile;
    const formData = new FormData();
    const extension = blop.type.split("/")[1];
    const file = new File([blop], `${Date.now()}.${extension}`, {
      type: blop.type
    });
    formData.append("avatar" + avatar, file);
    let response = await server.post.avatar({
      profile: profile,
      avatar: avatar,
      form: formData
    });
    if (response.status === 200) {
      this.gridPartners.current.refresh();
      showPopUp(
        "Fotografía actualizada..!",
        "La fotografía de perfil del socio actualizó con éxito.",
        null,
        "admin"
      );
    } else {
      if (response.status === 404) {
        showPopUp(
          "Ups..!",
          "No ha cargado ninguna fotografía..!",
          null,
          "admin"
        );
      } else {
        showPopUp(
          "Ups..!",
          "Ocurrio un error al procesar la imagen.",
          null,
          "admin"
        );
      }
    }
  };

  handleEditPartnerClick = e => {
    this.getGridRowDataPartners({
      id: $(e.target)
        .parents(".ag-row:first")
        .attr("row-id")
    });
  };

  handleDeletePartnerClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea eliminar el socio?",
      "¡Piénselo bien!<br/>La mayoría de las cosas se arreglan charlando.",
      null,
      "admin",
      async () => {
        let response = await server.delete.profile({
          id: id
        });
        if (response.status === 204) {
          this.gridPartners.current.refresh();
          showPopUp(
            "Socio eliminado..!",
            "El socio se eliminó con éxito.<br/>Siento mucho su perdida, la próxima vez elija mejor.",
            null,
            "admin"
          );
        }
      }
    );
  };

  handlePreviewPartnerClick = e => {
    this.openProfile(e);
  };

  handleEditDetailClick = e => {
    this.getGridRowDataDetail({
      profile: this.state.selected,
      id: $(e.target)
        .parents(".ag-row:first")
        .attr("row-id")
    });
  };

  handleDeleteDetailClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea eliminar el detalle del perfil?",
      "¡Piénselo bien!<br/>No se podrá recuperar una vez eliminado.",
      null,
      "admin",
      async () => {
        let response = await server.delete.profileDetail({
          id: id,
          profile: this.state.selected
        });
        if (response.status === 204) {
          this.refreshGrids();
          showPopUp(
            "Detalle del perfil eliminado..!",
            "El detalle del perfil eliminó con éxito.<br/>La próxima vez no mienta.!",
            null,
            "admin"
          );
        }
      }
    );
  };

  openProfile = async e => {
    let profile = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    let response = await server.get.profile({
      profile: profile
    });
    if (response.status === 200) {
      SwalPopUp.fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: "swal-profile",
        html: (
          <React.Fragment>
            <div
              className={`popup-profile-container ${
                response.data.items.length === 0
                  ? "popup-profile-acordion-empty"
                  : ""
              }`}
            >
              <Markup content={response.data.text} />
              <div className="popup-profile-acordion-container">
                <Accordion items={response.data.items} />
              </div>
            </div>
          </React.Fragment>
        ),
        buttonsStyling: false,
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: false
      });
    }
  };

  onRowSelected = id => {
    this.setState({ selected: id });
    this.gridDetail.current.refresh();
  };

  getSelectedId = () => {
    return this.state.selected;
  };

  refreshGrids = () => {
    this.gridDetail.current.refresh();
    this.gridPartners.current.refresh();
  };

  render() {
    return (
      <Fragment>
        <PartnersGrid
          columnDefs={this.columnDefsPartner}
          getDataSource={this.getDataSourcePartners}
          ref={this.gridPartners}
          grid={this.gridPartners}
          onRowSelected={this.onRowSelected}
          refreshGrids={this.refreshGrids}
        />
        <PartnersDetailGrid
          columnDefs={this.columnDefsDetail}
          getDataSource={this.getDataSourceDetail}
          ref={this.gridDetail}
          grid={this.gridDetail}
          getSelectedId={this.getSelectedId}
          refreshGrids={this.refreshGrids}
        />
      </Fragment>
    );
  }
}
