import React, { Component } from "react";
import Menu from "../../menu";
import Footer from "../../footer";
import BooksBanner from "./banner";
import BooksSection from "./section";

export default class Books extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu index={5} />
        <BooksBanner />
        <BooksSection />
        <Footer index={5} />
      </React.Fragment>
    );
  }
}
