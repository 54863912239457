import React, { Component, Fragment } from "react";

export default class GridCellSpan extends Component {
  render() {
    return (
      <Fragment>
        <span className={this.props.className}>{this.props.text}</span>
      </Fragment>
    );
  }
}
