import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PartnersProfileEditor from "./PartnersProfileEditor";
import { server } from "../../apis";
import { AgGridReact } from "ag-grid-react";
import GridBase from "../common/GridBase";
import { showPopUp, showQuestionPopUp } from "../common";

import $ from "jquery";

const _swalPopUp = withReactContent(Swal);

export default class GridPartner extends GridBase {
  constructor(props) {
    super(props);
  }

  handleNewPartnerClick = async e => {
    this.openPopUpEditor({});
  };

  handleApplyClick = async e => {
    showQuestionPopUp(
      "¿Desea aplicar los cambios?",
      "¡Piénselo bien!<br/>Al aplicar los cambios, los perfiles se pondrán en línea.<br/>Verifique que no haya otras personas trabajando con los perfiles.",
      null,
      "admin",
      async () => {
        let response = await server.put.json("profiles");
        if (response.status === 201) {
          //this.refresh();
          this.props.refreshGrids();
          showPopUp(
            "Puesta en línea..!",
            "Los perfiles se pusieron en línea exitosamente.",
            null,
            "admin"
          );
        } else {
          if (response.status === 200) {
            showPopUp(
              "Ups..!",
              "No ha publicado ningún perfil..!",
              null,
              "admin"
            );
          } else {
            showPopUp(
              "Ups..!",
              "Ocurrio un error al procesar la imagen.",
              null,
              "admin"
            );
          }
        }
      }
    );
  };

  openPopUpEditor = data => {
    $.extend(
      true,
      {
        id: "",
        title: "",
        detail: "",
        content: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: ""
      },
      data
    );
    //alert(JSON.stringify(data));
    let partnersProfileEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 1200,
        html: (
          <div className="html-editor-popup">
            <PartnersProfileEditor
              ref={ref => {
                partnersProfileEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = partnersProfileEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          ///alert(JSON.stringify(data));
          let response = await server.post.profile(data);
          if (response.status === 200) {
            await this.refresh();
          }
        }
      });
  };

  onSelectionChanged = () => {
    this.props.onRowSelected(this.gridApi.getSelectedRows()[0].id);
  };

  onGridReadyPartner = async params => {
    await this.onGridReady(params);
    this.gridApi.forEachNode(node =>
      node.rowIndex ? 0 : node.setSelected(true)
    );
  };

  render() {
    return (
      <div className="ag-theme-blue ag-grid-container">
        <div
          style={{
            marginBottom: "5px",
            maxHeight: "40px",
            minHeight: "40px",
            height: "40px"
          }}
        >
          <button
            className="text-uppercase admin-button"
            onClick={this.handleNewPartnerClick}
          >
            <i className="fa fa-user" />
            Nuevo socio
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.handleApplyClick}
          >
            <i className="fa fa-gear" />
            Aplicar
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.refresh}
          >
            <i className="fa fa-refresh" /> Actualizar
          </button>
        </div>
        <div style={{ height: "calc(40vh - 50px)" }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            getRowNodeId={data => data.id}
            modules={this.state.modules}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            statusBar={this.state.statusBar}
            paginationPageSize={this.state.paginationPageSize}
            enableRangeSelection={false}
            onSelectionChanged={this.onSelectionChanged}
            rowSelection={this.state.rowSelection}
            enableCharts={false}
            localeText={this.state.localeText}
            onGridReady={this.onGridReadyPartner}
          />
        </div>
      </div>
    );
  }
}
