import React, { Component, Fragment } from "react";
import HtmlEditor from "../common/HtmlEditor";
import $ from "jquery";

export default class PublishingsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        content: ""
      }
    };
    $.extend(true, this.state.data, this.props.data);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  extendState = content => {
    this.setState({
      data: $.extend(true, this.state.data, { content: content })
    });
  };

  handleContentChange = content => {
    this.extendState(content);
    this.validateHtmlEditor();
  };

  validate = () => {
    let isvalid = this.state.data.content.length >= 100;
    if (isvalid) {
      this.extendState(this.state.data.content);
      return this.state.data;
    }
    this.validateHtmlEditor();
    return false;
  };

  validateHtmlEditor = () => {
    let message = "Ingrese una texto más largo, para la publicación.";
    let editor = $("div.note-editor.note-frame.panel.panel-default");
    let div = $(editor).next();
    if (this.state.data.content.length >= 100) {
      if (div !== null && div.hasClass("errors-container")) div.remove();
    } else {
      if (div == null || !div.hasClass("errors-container")) {
        div = $("<div/>");
        div.addClass("errors-container");
        let span = $("<span/>");
        span.html(message).addClass("errors active");
        div.append(span);
        editor.parent().append(div);
      } else if (div !== null) {
        let span = $(div).next();
        if (span.html() !== message) span.html(message);
      }
    }
  };

  render() {
    return (
      <Fragment>
        <section className="publishings-editor">
          <div
            id="html-editor-container"
            className="container field-html-editor"
          >
            <div className="row">
              <div className="col-lg-12 field-html-editor">
                <HtmlEditor
                  handleContentChange={this.handleContentChange}
                  content={this.props.data.content}
                  height={510}
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
