import React, { Component, Fragment } from "react";
import HtmlEditor from "../common/HtmlEditor";
import { validateInputLength } from "../common";
import $ from "jquery";

export default class PartnersProfileEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        title: "",
        detail: "",
        content: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: ""
      }
    };
    $.extend(true, this.state.data, this.props.data);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  extendState = content => {
    this.setState({
      data: $.extend(
        true,
        $.extend(true, this.state.data, {
          title: $("#title").val(),
          detail: $("#detail").val(),
          facebook: $("#facebook").val(),
          twitter: $("#twitter").val(),
          instagram: $("#instagram").val(),
          linkedin: $("#linkedin").val()
        }),
        {
          content: content
        }
      )
    });
  };

  handleContentChange = content => {
    this.extendState(content);
    this.validateHtmlEditor();
  };

  validate = () => {
    this.validateForm();
    let isvalid =
      !$("#title").hasClass("not-valid") &&
      !$("#detail").hasClass("not-valid") &&
      this.state.data.content.length >= 100;
    if (isvalid) {
      this.extendState(this.state.data.content);
      return this.state.data;
    }
    this.validateHtmlEditor();
    return false;
  };

  validateHtmlEditor = () => {
    let message = "Ingrese una descripción más larga, para su perfil.";
    //let editor = $("div.note-editable.panel-body");
    let editor = $("div.note-editor.note-frame.panel.panel-default");
    let div = $(editor).next();
    //console.log(this.state.data.content.length);
    if (this.state.data.content.length >= 100) {
      if (div !== null && div.hasClass("errors-container")) div.remove();
    } else {
      if (div == null || !div.hasClass("errors-container")) {
        div = $("<div/>");
        div.addClass("errors-container");
        let span = $("<span/>");
        span.html(message).addClass("errors active");
        div.append(span);
        editor.parent().append(div);
      } else if (div !== null) {
        let span = $(div).next();
        if (span.html() !== message) span.html(message);
      }
    }
  };

  validateForm = () => {
    validateInputLength(
      $("#title"),
      "Ingrese su nombre completo (10 caracteres como mínimo)",
      10
    );
    validateInputLength(
      $("#detail"),
      "Ingrese su especialidad (10 caracteres como mínimo)",
      10
    );
  };

  render() {
    return (
      <Fragment>
        <section className="profile-editor section_gap">
          <div className="container field-social">
            <div className="admin_form">
              <div className="col-lg-12 field-social">
                <form
                  className="admin_form"
                  id="profile-editor-form"
                  noValidate={true}
                >
                  <div className="row">
                    <div className="col-md-6 field-validate">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control not-valid"
                          id="title"
                          name="title"
                          defaultValue={this.state.data.title}
                          placeholder="Ingrese su nombre completo"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder =
                              "Ingrese su nombre completo")
                          }
                          onKeyUp={e => {
                            validateInputLength(
                              $(e.target),
                              "Ingrese su nombre completo (10 caracteres como mínimo)",
                              10
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 field-validate">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control not-valid"
                          id="detail"
                          name="detail"
                          defaultValue={this.state.data.detail}
                          placeholder="Ingrese su especialidad"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder = "Ingrese su especialidad")
                          }
                          onKeyUp={e => {
                            validateInputLength(
                              $(e.target),
                              "Ingrese su especialidad (10 caracteres como mínimo)",
                              10
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 field-social">
                      <span className="field-social">
                        <i
                          className="fa fa-facebook fa-social"
                          aria-hidden="false"
                        ></i>
                      </span>
                      <div className="input-group field-social">
                        <input
                          type="text"
                          className="form-control not-valid"
                          id="facebook"
                          name="facebook"
                          defaultValue={this.state.data.facebook}
                          placeholder="Ingrese el link a su perfil de Facebook"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder =
                              "Ingrese el link a su perfil de Facebook")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 field-social">
                      <span className="field-social">
                        <i
                          className="fa fa-twitter fa-social"
                          aria-hidden="false"
                        ></i>
                      </span>
                      <div className="input-group field-social">
                        <input
                          type="text"
                          className="form-control not-valid"
                          id="twitter"
                          name="twitter"
                          defaultValue={this.state.data.twitter}
                          placeholder="Ingrese el link a su perfil de Twitter"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder =
                              "Ingrese el link a su perfil de Twitter")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 field-social">
                      <span className="field-social">
                        <i
                          className="fa fa-instagram fa-social"
                          aria-hidden="false"
                        ></i>
                      </span>
                      <div className="input-group field-social">
                        <input
                          type="text"
                          className="form-control not-valid"
                          id="instagram"
                          name="instagram"
                          defaultValue={this.state.data.instagram}
                          placeholder="Ingrese el link a su perfil de Instagram"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder =
                              "Ingrese el link a su perfil de Instagram")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 field-social">
                      <span className="field-social">
                        <i
                          className="fa fa-linkedin fa-social"
                          aria-hidden="false"
                        ></i>
                      </span>
                      <div className="input-group field-social">
                        <input
                          type="text"
                          className="form-control not-valid"
                          id="linkedin"
                          name="linkedin"
                          defaultValue={this.state.data.linkedin}
                          placeholder="Ingrese el link a su perfil de Linkedin"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder =
                              "Ingrese el link a su perfil de Linkedin")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="profile-editor section_gap">
          <div
            id="html-editor-container"
            className="container field-html-editor"
          >
            <div className="row">
              <div className="col-lg-12 field-html-editor">
                <HtmlEditor
                  handleContentChange={this.handleContentChange}
                  content={this.props.data.content}
                  height={390}
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
