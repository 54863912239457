import React, { Component } from "react";
import Menu from "../menu";
import Footer from "../footer";
import ServicesBanner from "./banner";
import ServicesSection from "./section";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu index={3} />
        <ServicesBanner />
        <ServicesSection />
        <Footer index={3} />
      </React.Fragment>
    );
  }
}
