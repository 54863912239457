import React, { Component, Fragment } from "react";
import MessagesGrid from "./MessagesGrid";
import { server } from "../../apis";
import $ from "jquery";
import { showPopUp, showQuestionPopUp } from "../common";
import SwalPopUp from "../common/SwalPopUp";

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.grid = React.createRef();
  }

  columnDefs = [
    {
      headerName: "Nombre",
      field: "name"
    },
    {
      headerName: "E-mail",
      field: "email"
    },
    {
      headerName: "Asunto",
      field: "subject"
    },
    {
      headerName: "IP",
      field: "remoteAddr",
      width: 120
    },
    {
      headerName: "Dispositivo",
      field: "userAgent",
      width: 250
    },
    {
      headerName: "Fecha",
      field: "date",
      width: 100
    },
    {
      headerName: "",
      field: "message",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleViewMessageClick}
              title="Leer mensaje..."
            >
              <i className="fa fa-search ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "delete",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleDeleteClick}
              title="Eliminar..."
            >
              <i className="fa fa-trash ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    }
  ];

  getDataSource = async () => {
    let response = await server.get.messageData();
    if (response.status === 200) {
      return response.data;
    }
    return [];
  };

  handleDeleteClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea eliminar el mensaje?",
      "¡Piénselo bien!<br/>Si elimina el mensaje, no se podrá recuperar.",
      null,
      "admin",
      async () => {
        let response = await server.delete.message({
          id: id
        });
        if (response.status === 204) {
          this.grid.current.refresh();
          showPopUp(
            "Mensaje eliminado..!",
            "El mensaje se eliminó con éxito.",
            null,
            "admin"
          );
        }
      }
    );
  };

  handleViewMessageClick = async e => {
    this.openMessage(e);
  };

  openMessage = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    let response = await server.get.message({
      id: id
    });
    if (response.status === 200) {
      SwalPopUp.fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: "swal-message",
        html: (
          <React.Fragment>
            <div className="popup-message-container">
              {response.data.message}
            </div>
          </React.Fragment>
        ),
        buttonsStyling: false,
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: false
      });
    }
  };

  render() {
    return (
      <Fragment>
        <MessagesGrid
          columnDefs={this.columnDefs}
          getDataSource={this.getDataSource}
          ref={this.grid}
          grid={this.grid}
        />
      </Fragment>
    );
  }
}
