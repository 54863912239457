import React, { Component } from "react";
import { buildPublishingsTable } from "./";

export default class PublishingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishings: this.props.publishings,
      jsonName: this.props.jsonName
    };
    this.onClick = this.props.onClick ? this.props.onClick.bind(this) : null;
  }

  render() {
    return (
      <React.Fragment>
        {this.state.publishings &&
          buildPublishingsTable(
            this.state.publishings,
            this.state.jsonName,
            this.onClick
          )}
      </React.Fragment>
    );
  }
}
