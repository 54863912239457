import React, { Component, Fragment } from "react";

export default class GridCellImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div className="grid-cell-image-container" onClick={this.props.onClick}>
          <img src={this.props.src} className={this.props.className} />
        </div>
      </Fragment>
    );
  }
}
