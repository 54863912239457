import React, { Component, Fragment } from "react";
import { server } from "../../apis";
import $ from "jquery";
import { showPopUp, showQuestionPopUp } from "../common";
import PublishingsGrid from "./PublishingsGrid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PublishingsPreviewEditor from "./PublishingsPreviewEditor";
import PublishingsEditor from "./PublishingsEditor";
import PublishingsTable from "../publishings/PublishingsTable";
import { Markup } from "interweave";
import SwalPopUp from "../common/SwalPopUp";

const _swalPopUp = withReactContent(Swal);

export default class Publishings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.grid = React.createRef();
  }

  columnDefs = [
    {
      headerName: "Tipo",
      field: "category",
      width: 120
    },
    {
      headerName: "Título",
      field: "title",
      width: 200
    },
    {
      headerName: "Autor",
      field: "author"
    },
    {
      headerName: "Firma",
      field: "signature"
    },
    {
      headerName: "Fecha",
      field: "date"
    },
    {
      headerName: "",
      field: "editSummary",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleEditSummaryClick}
              title="Editar resumen..."
            >
              <i className="fa fa-pencil ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "editContent",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleEditContentClick}
              title="Editar publicación..."
            >
              <i className="fa fa-pencil ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "preview",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handlePreviewClick}
              title="Vista previa..."
            >
              <i className="fa fa-search ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "delete",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleDeleteClick}
              title="Eliminar..."
            >
              <i className="fa fa-trash ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    },
    {
      headerName: "",
      field: "mail",
      width: 40,
      filter: false,
      resizable: false,
      cellRendererFramework: params => {
        let className =
          "fa fa-envelope " +
          (params.value === "1" ? "fa-green" : "fa-red") +
          " ag-grid-icon";
        let title =
          params.value === "1"
            ? "Se enviaron notificaciones..."
            : "No se enviaron notificaciones...";
        return (
          <Fragment>
            <div className="ag-grid-cell-icon" title={title}>
              <i className={className} />
            </div>
          </Fragment>
        );
      }
    },
    {
      headerName: "",
      field: "ready",
      width: 40,
      filter: false,
      resizable: false,
      cellRendererFramework: params => {
        let className =
          "fa " +
          (params.value === "1"
            ? "fa-thumbs-up fa-green"
            : "fa-thumbs-down fa-red") +
          " ag-grid-icon";
        let title = params.value === "1" ? "En línea..." : "Fuera de línea...";
        return (
          <Fragment>
            <div className="ag-grid-cell-icon" title={title}>
              <i className={className} />
            </div>
          </Fragment>
        );
      }
    }
  ];

  getDataSource = async () => {
    let response = await server.get.publishings();
    if (response.status === 200) {
      return response.data;
    }
    return [];
  };

  handleDeleteClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea eliminar la publicación?",
      "¡Piénselo bien!<br/>Si elimina la publicación, no se podrá recuperar.",
      null,
      "admin",
      async () => {
        let response = await server.delete.publishings({
          id: id
        });
        if (response.status === 204) {
          this.grid.current.refresh();
          showPopUp(
            "Publicación eliminada..!",
            "La publicación se eliminó con éxito.",
            null,
            "admin"
          );
        }
      }
    );
  };

  handleEditSummaryClick = e => {
    this.getGridRowDataSummary({
      id: $(e.target)
        .parents(".ag-row:first")
        .attr("row-id")
    });
  };

  getGridRowDataSummary = async data => {
    let response = await server.get.publishings(data);
    if (response.status === 200) {
      this.openPopUpEditSummaryPublishing(response.data);
    }
  };

  openPopUpEditSummaryPublishing = data => {
    $.extend(
      true,
      {
        id: "",
        categoryId: "",
        title: "",
        author: "",
        signature: "",
        date: "",
        summary: "",
        content: ""
      },
      data
    );
    let publishingsPreviewEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 600,
        html: (
          <div className="html-editor-popup">
            <PublishingsPreviewEditor
              ref={ref => {
                publishingsPreviewEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = publishingsPreviewEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          //console.log(JSON.stringify(data));
          let response = await server.put.publishings(data);
          if (response.status === 200) {
            this.grid.current.refresh();
          }
        }
      });
  };

  handleEditContentClick = async e => {
    this.getGridRowDataContent({
      id: $(e.target)
        .parents(".ag-row:first")
        .attr("row-id")
    });
  };

  getGridRowDataContent = async data => {
    let response = await server.get.publishings(data);
    if (response.status === 200) {
      this.openPopUpEditContentPublishing(response.data);
    }
  };

  openPopUpEditContentPublishing = data => {
    $.extend(
      true,
      {
        id: "",
        content: ""
      },
      data
    );
    let publishingsEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 1200,
        html: (
          <div className="html-editor-popup">
            <PublishingsEditor
              ref={ref => {
                publishingsEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = publishingsEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          //console.log(JSON.stringify(data));
          let response = await server.put.publishingsContent(data);
          if (response.status === 200) {
            this.grid.current.refresh();
          }
        }
      });
  };

  handlePreviewClick = async e => {
    let response = await server.get.publishings({
      id: $(e.target)
        .parents(".ag-row:first")
        .attr("row-id")
    });
    if (response.status === 200) {
      this.openPreview(response.data);
    }
  };

  handleReadMorePreviewClick = async e => {
    let response = await server.get.publishings({
      id: $(e.target).attr("data-id")
    });
    if (response.status === 200) {
      let title = response.data.title ? response.data.title : "";
      let content = response.data.content ? response.data.content : "";
      SwalPopUp.fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: "swal-publishings",
        html: (
          <React.Fragment>
            <div className="popup-publishings-container">
              <Markup
                content={
                  "<h1 class='publishings-title'>" +
                  title +
                  "</h1></br>" +
                  content
                }
              />
            </div>
          </React.Fragment>
        ),
        buttonsStyling: false,
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: false
      });
    }
  };

  openPreview = data => {
    $.extend(
      true,
      {
        id: "",
        categoryId: "",
        title: "",
        author: "",
        signature: "",
        date: "",
        summary: "",
        content: ""
      },
      data
    );
    let jsonName = data.categoryId === "1" ? "press" : "book";
    _swalPopUp.fire({
      showClass: {
        popup: "animated fadeInDown faster"
      },
      customClass: {
        container: "swal2-container-admin",
        popup: "swal2-popup-admin",
        header: "swal2-header-admin",
        title: "swal2-title-admin",
        closeButton: "swal2-close-button-admin",
        content: "swal2-content-admin",
        input: "swal2-input-admin",
        actions: "swal2-actions-admin",
        footer: "swal2-footer-admin"
      },
      width: 390,
      html: (
        <div className="publishings-admin-preview">
          <PublishingsTable
            publishings={[data]}
            jsonName={jsonName}
            onClick={this.handleReadMorePreviewClick}
          />
        </div>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false,
      onOpen: () => {
        $(".swal2-confirm:focus").blur();
        $(".publishings-preview").focus();
      }
    });
  };

  render() {
    return (
      <Fragment>
        <PublishingsGrid
          columnDefs={this.columnDefs}
          getDataSource={this.getDataSource}
          ref={this.grid}
          grid={this.grid}
        />
      </Fragment>
    );
  }
}
