import React, { Component } from "react";
import SwalPopUp from "../../common/SwalPopUp";
import { Markup } from "interweave";
import Accordion from "../../common/Accordion";
import { server } from "../../../apis";

export default class TeamSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: null
    };
    this.openProfile = this.openProfile.bind(this);
  }

  async componentDidMount() {
    let response = await server.get.json("profiles");
    if (response.status === 200) {
      this.setState({ profiles: response.data });
    }
  }

  openProfile = async e => {
    let target = e.currentTarget.parentElement.parentElement;
    target = target.attributes["data-profile"] ? target : target.parentElement;
    let profile = target.attributes["data-profile"].value;
    let response = await server.get.json("profile-" + profile);
    if (response.status === 200) {
      SwalPopUp.fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: "swal-profile",
        html: (
          <React.Fragment>
            <div
              className={`popup-profile-container ${
                response.data.items.length == 0
                  ? "popup-profile-acordion-empty"
                  : ""
              }`}
            >
              <Markup content={response.data.text} />
              <div className="popup-profile-acordion-container">
                <Accordion items={response.data.items} />
              </div>
            </div>
          </React.Fragment>
        ),
        buttonsStyling: false,
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: false
        /*onClose: () => {
          setTimeout(() => {
            this.fullScreen(false);
          }, 500);
        }*/
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="section_gap team-area">
          <div id="container-scroll" className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="main_title"></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="main_title">
                  <h2>Integrantes</h2>
                  <p>
                    El estudio jurídico se especializa en Derecho Penal y cuenta
                    un con área de Derecho Privado. Sus integrantes y
                    colaboradores se capacitan y actualizan en forma permanente
                    para brindar un servicio eficaz y de resolución de
                    conflictos a sus clientes.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {this.state &&
                this.state.profiles &&
                this.state.profiles.map((profile, key) => {
                  return (
                    <div
                      {...{
                        className: "col-lg-4 col-md-4 col-sm-6 col-team-member",
                        key
                      }}
                    >
                      <div
                        className="single_member"
                        data-profile={profile.id}
                        data-title={profile.title}
                      >
                        <div className="profile">
                          <div className="profile-image">
                            <img
                              className="img-fluid profile-link"
                              onClick={this.openProfile}
                              src={`images/team/${profile.primaryAvatar}`}
                              alt=""
                            />
                            <div className="profile-desc">
                              <div>
                                <h5>{profile.title}</h5>
                              </div>
                              <p className="profession">{profile.detail}</p>
                            </div>
                          </div>
                          <div className="profile-sumary">
                            <h5>{profile.title}</h5>
                            <a
                              className="profile-link"
                              onClick={this.openProfile}
                            >
                              Leer más...
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {/*
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="main_title">
                  <h2>
                  </h2>
                  <p>
                  </p>
                </div>
              </div>
            </div>
            */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
