import React from "react";
import styled from "styled-components";
import ItemsCarousel from "react-items-carousel";

const noOfCards = 1;
const autoPlayDelay = 10000;
const chevronWidth = 0;

const Wrapper = styled.div`
  padding: 0px;
  max-width: 1000px;
  margin: 0 auto;
`;

const SlideItem = styled.div`
  height: 400px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
`;

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: 0,
      items: this.props.items
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () =>
    this.setState(prevState => ({
      activeItemIndex:
        (prevState.activeItemIndex + 1) %
        (this.props.items.length - noOfCards + 1)
    }));

  onChange = value => this.setState({ activeItemIndex: value });

  handleChevronClick = e => {
    clearInterval(this.interval);
    this.interval = setInterval(this.tick, autoPlayDelay);
  };

  render() {
    return (
      <Wrapper>
        <ItemsCarousel
          infiniteLoop={true}
          gutter={20}
          numberOfCards={noOfCards}
          activeItemIndex={this.state.activeItemIndex}
          requestToChangeActive={this.onChange}
          leftChevron={
            <button
              className="button-chevron-carousel"
              onClick={this.handleChevronClick}
            >
              <i className="fa fa-chevron-left fa-chevron-carousel left" />
            </button>
          }
          rightChevron={
            <button
              className="button-chevron-carousel"
              onClick={this.handleChevronClick}
            >
              <i className="fa fa-chevron-right fa-chevron-carousel right" />
            </button>
          }
          chevronWidth={chevronWidth}
          outsideChevron={false}
        >
          {this.state &&
            this.state.items &&
            this.state.items.map((item, key) => (
              <SlideItem key={key}>
                <img
                  src={`/images/carousel/${item.image}`}
                  className="img-fluid-carousel"
                  alt={`Imagen ${key}`}
                />
              </SlideItem>
            ))}
        </ItemsCarousel>
      </Wrapper>
    );
  }
}
