import React, { Component } from "react";
import { server } from "../../../../apis";
import PublishingsTable from "../../PublishingsTable";

export default class PressSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: null
    };
  }

  componentDidMount = async () => {
    let response = await server.get.json("press-summary");
    if (response.status === 200) {
      this.setState({ summary: response.data });
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="service-area section_gap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="main_title">
                  <h1>Publicaciones en Medios</h1>
                </div>
              </div>
            </div>
            {this.state.summary && (
              <PublishingsTable
                publishings={this.state.summary}
                jsonName="press"
              />
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
