import React, { Component } from "react";
import $ from "jquery";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    $("#menu-" + this.props.index).addClass("active");
    $(".nav-link")
      .not(".dropdown-toggle")
      .click(function() {
        $(".navbar-collapse").removeClass("show");
      });
  }

  render() {
    return (
      <header className="header_area">
        <div className="main_menu">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              <a className="navbar-brand logo_h" href="/home">
                <div className="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div
                className="collapse navbar-collapse offset"
                id="navbarSupportedContent"
              >
                <ul className="nav navbar-nav menu_nav ml-auto">
                  <li id="menu-1" className="nav-item">
                    <a className="nav-link" href="/home">
                      <span className="nav-item-text">Inicio</span>
                    </a>
                  </li>
                  <li id="menu-2" className="nav-item">
                    <a className="nav-link" href="/about">
                      <span className="nav-item-text">Historia</span>
                    </a>
                  </li>
                  <li id="menu-4" className="nav-item">
                    <a className="nav-link" href="/team">
                      <span className="nav-item-text">Nosotros</span>
                    </a>
                  </li>
                  <li id="menu-3" className="nav-item">
                    <a className="nav-link" href="/services">
                      <span className="nav-item-text">Servicios</span>
                    </a>
                  </li>
                  <li id="menu-5" className="nav-item submenu dropdown">
                    <a
                      href="#"
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Publicaciones
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a className="nav-link" href="/press">
                          Medios
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/books">
                          Doctrina
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li id="menu-6" className="nav-item">
                    <a className="nav-link" href="/contact">
                      <span className="nav-item-text">Contacto</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
