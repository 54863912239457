import React, { Component } from "react";

export class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.src
    };
  }

  static defaultProps = {
    height: "420",
    width: "100%"
  };

  render() {
    return (
      <div className="mapBox mapBox_link">
        <div className="mapBox_link_inner overlay">
          <iframe
            src={this.state.src}
            width={this.props.width}
            height={this.props.height}
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            title="Geo"
          ></iframe>
        </div>
      </div>
    );
  }
}
