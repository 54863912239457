import React, { Component } from "react";
import { server } from "../../../apis";
import Carousel from "../../common/Carousel";

export default class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: null
    };
  }

  async componentWillMount() {
    let response = await server.get.json("carousel");
    if (response.status === 200) {
      this.setState({
        images: response.data
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="about-sec-area section_gap_top" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 about-sec-area-left">
                <h1>La historia de nuestro estudio</h1>
                <p>
                  El estudio se encuentra conformado por los Dres. Jorge Daniel
                  Pirozzo, Alejandro Buigo y Daniela Mezzalira. Desde hace 17
                  años el Dr. Pirozzo, luego de dejar el Poder Judicial se
                  dedicó a la actividad privada, ejerciendo el Derecho en la
                  rama penal en todas sus áreas. Luego se sumó el Dr. Alejandro
                  Buigo, quien también dejando la carrera judicial, se sumó al
                  ejercicio profesional conformando ambos el estudio en el área
                  de Derecho Penal, rama del Derecho en la cual ambos se han
                  especializado y en la cual desarrollan a su vez su actividad
                  académica. Posteriormente, se sumó la Dra. Mezzalira,
                  proveniente de la función pública en el Estado Nacional, quien
                  aportó al estudio sus conocimientos en el área de derecho
                  privado. De ese modo el Estudio hoy brinda asesoramiento a sus
                  clientes especialmente en materia penal, pero también en
                  cuestiones vinculadas al Derecho Civil, Comercial,
                  Administrativo y Laboral.
                </p>
              </div>
              <div className="col-lg-6 about-sec-area-right">
                {this.state.images && (
                  <Carousel items={this.state.images}></Carousel>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
