import React from "react";
import { AgGridReact } from "ag-grid-react";
import GridBase from "../common/GridBase";
import { server } from "../../apis";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showPopUp, showQuestionPopUp } from "../common";
import $ from "jquery";
import PublishingsPreviewEditor from "./PublishingsPreviewEditor";

const _swalPopUp = withReactContent(Swal);

export default class PublishingsGrid extends GridBase {
  constructor(props) {
    super(props);
  }

  handleNewClick = e => {
    this.openPopUpNewPublishing();
  };

  openPopUpNewPublishing = () => {
    let data = {
      id: "",
      categoryId: "",
      title: "",
      author: "",
      signature: "",
      date: "",
      summary: "",
      content: ""
    };
    let publishingsPreviewEditorRef;
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 600,
        html: (
          <div className="html-editor-popup">
            <PublishingsPreviewEditor
              ref={ref => {
                publishingsPreviewEditorRef = ref;
              }}
              data={data}
            />
          </div>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: '<i class="fa fa-save fa-button"></i> Guardar',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-ban fa-button"></i> Cancelar',
        preConfirm: () => {
          data = publishingsPreviewEditorRef.validate();
          return data;
        },
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
          //console.log(JSON.stringify(data));
          let response = await server.post.publishings(data);
          if (response.status === 201) {
            await this.refresh();
          }
        }
      });
  };

  handleApplyClick = async e => {
    showQuestionPopUp(
      "¿Desea aplicar los cambios?",
      "¡Piénselo bien!<br/>Al aplicar los cambios, las publicaciones se pondrán en línea.<br/>Verifique que no haya otras personas trabajando con las publicaciones.",
      null,
      "admin",
      async () => {
        let response = await server.put.json("publishings-summary");
        if (response.status === 201) {
          this.refresh();
          showPopUp(
            "Puesta en línea..!",
            "Las publicaciones se pusieron en línea exitosamente.",
            null,
            "admin"
          );
        } else {
          if (response.status === 200) {
            showPopUp(
              "Ups..!",
              "No ha publicado ninguna publicación..!",
              null,
              "admin"
            );
          } else {
            showPopUp(
              "Ups..!",
              "Ocurrio un error al procesar la imagen.",
              null,
              "admin"
            );
          }
        }
      }
    );
  };

  render() {
    return (
      <div className="ag-theme-blue ag-grid-container">
        <div
          style={{
            marginBottom: "5px",
            maxHeight: "40px",
            minHeight: "40px",
            height: "40px"
          }}
        >
          <button
            className="text-uppercase admin-button"
            onClick={this.handleNewClick}
          >
            <i className="fa fa-newspaper-o" />
            Nueva Publicación
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.handleApplyClick}
          >
            <i className="fa fa-gear" />
            Aplicar
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.refresh}
          >
            <i className="fa fa-refresh" /> Actualizar
          </button>
        </div>
        <div style={{ height: "calc(100vh - 50px)" }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            getRowHeight={this.getRowHeight}
            getRowNodeId={data => data.id}
            modules={this.state.modules}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            statusBar={this.state.statusBar}
            paginationPageSize={this.state.paginationPageSize}
            enableRangeSelection={false}
            enableCharts={false}
            localeText={this.state.localeText}
            onGridReady={this.onGridReady}
          />
        </div>
      </div>
    );
  }
}
