import React from "react";
import SwalPopUp, { SwalPopUpFooter, SwalPopUpTitle } from "./SwalPopUp";
import { Markup } from "interweave";
import { GoogleMap } from "../contact/map";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";

const _swalPopUp = withReactContent(Swal);

export const showPopUp = (title, body, icon, classSufix) => {
  classSufix = classSufix ? classSufix : "site";
  //icon = icon ? icon : "none";
  SwalPopUp.fire({
    title: <SwalPopUpTitle title={title} />,
    icon: icon,
    showClass: {
      popup: "animated fadeInDown faster"
    },
    customClass: {
      container: "swal2-container-" + classSufix,
      popup: "swal2-popup-" + classSufix,
      header: "swal2-header-" + classSufix,
      title: "swal2-title-" + classSufix,
      closeButton: "swal2-close-button-" + classSufix,
      content: "swal2-content-" + classSufix,
      input: "swal2-input-" + classSufix,
      actions: "swal2-actions-" + classSufix,
      footer: "swal2-footer-site"
    },
    width: 600,
    html: (
      <Markup content={"<div class='popup-container'>" + body + "</div>"} />
    ),
    footer: <SwalPopUpFooter />,
    buttonsStyling: false,
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: false,
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
    onOpen: () => {
      $(".swal2-confirm:focus").blur();
    }
  });
};

export const showQuestionPopUp = (title, body, icon, classSufix, callBack) => {
  classSufix = classSufix ? classSufix : "site";
  //icon = icon ? icon : "none";
  _swalPopUp
    .fire({
      title: <SwalPopUpTitle title={title} />,
      icon: icon,
      showClass: {
        popup: "animated fadeInDown faster"
      },
      customClass: {
        container: "swal2-container-" + classSufix,
        popup: "swal2-popup-" + classSufix,
        header: "swal2-header-" + classSufix,
        title: "swal2-title-" + classSufix,
        closeButton: "swal2-close-button-" + classSufix,
        content: "swal2-content-" + classSufix,
        input: "swal2-input-" + classSufix,
        actions: "swal2-actions-" + classSufix,
        footer: "swal2-footer-site"
      },
      width: 600,
      html: (
        <Markup content={"<div class='popup-container'>" + body + "</div>"} />
      ),
      footer: <SwalPopUpFooter />,
      buttonsStyling: false,
      allowOutsideClick: false,
      showCloseButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      onOpen: () => {
        $(".swal2-confirm:focus").blur();
      }
    })
    .then(result => {
      if (result.value) {
        if (callBack) callBack();
      }
    });
};

export const showGoogleMapPopUp = src => {
  SwalPopUp.fire({
    showClass: {
      popup: "animated fadeInDown faster"
    },
    customClass: {
      container: "swal2-container-site",
      popup: "swal2-popup-site",
      header: "swal2-header-site",
      title: "swal2-title-site",
      closeButton: "swal2-close-button-site",
      content: "swal2-content-site",
      input: "swal2-input-site",
      actions: "swal2-actions-site",
      footer: "swal2-footer-site"
    },
    //width: 600,
    html: (
      <div className="map-popup">
        <GoogleMap src={src} />
      </div>
    ),
    footer: <SwalPopUpFooter />,
    buttonsStyling: false,
    allowOutsideClick: false,
    showCloseButton: true,
    showConfirmButton: false,
    onOpen: () => {
      $(".swal2-confirm:focus").blur();
    }
  });
};

export const showError = (el, message) => {
  let span = $(el).next();
  let input = $(el);
  input.addClass("not-valid");
  if (span == null || !span.hasClass("errors")) {
    span = $("<span/>");
    span.html(message).addClass("errors active");
    input.parent().append(span);
  } else if (span !== null && span.html() !== message) {
    span.html(message);
  }
};

export const hideError = el => {
  let span = $(el).next();
  let input = $(el);
  input.removeClass("not-valid");
  if (span !== null && span.hasClass("errors")) span.remove();
};

export const validateInputLength = (el, message, length) => {
  let value = el.val();
  if (value.length >= length) hideError(el);
  else showError(el, message);
};

export const device = () => {
  let ua = navigator.userAgent;
  let iphone = ua.match(/(iPhone|iPod|iPad)/);
  let blackberry = ua.match(/BlackBerry/);
  let android = ua.match(/Android/);
  let _device = {
    iphone: iphone,
    blackberry: blackberry,
    android: android,
    mobile: iphone || blackberry || android
  };
  return _device;
};

export const fullScreen = full => {
  if (device().android) {
    var document = window.document;
    var documentElement = document.documentElement;

    var requestFullScreen =
      documentElement.requestFullscreen ||
      documentElement.mozRequestFullScreen ||
      documentElement.webkitRequestFullScreen ||
      documentElement.msRequestFullscreen;
    var cancelFullScreen =
      document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (full) requestFullScreen.call(documentElement);
    } else {
      if (!full) cancelFullScreen.call(document);
    }
  }
};
