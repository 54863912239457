import React from "react";
import { AgGridReact } from "ag-grid-react";
import GridBase from "../common/GridBase";
import { server } from "../../apis";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showPopUp, showQuestionPopUp } from "../common";

const _swalPopUp = withReactContent(Swal);

export default class SubscriptionsGrid extends GridBase {
  constructor(props) {
    super(props);
  }

  onSendMailsClick = e => {
    showQuestionPopUp(
      "¿Desea enviar los mails a los suscriptores?",
      "¡Esto puede tardar!<br/>Se enviará un mail a cada suscriptor 'activo',<br/>informando de las nuevas publicaciones.",
      null,
      "admin",
      async () => {
        let response = await server.post.sendMails();
        if (response.status === 200) {
          this.refresh();
          showPopUp(
            "Envío exitoso..!",
            "Se enviarón las notificaciones de las publicaciones a todos los sucriptores 'activos'.",
            null,
            "admin"
          );
        } else {
          showPopUp(
            "Ups..!",
            "Ocurrio un error al procesar el envio de mails.",
            null,
            "admin"
          );
        }
      }
    );
  };

  render() {
    return (
      <div className="ag-theme-blue ag-grid-container">
        <div
          style={{
            marginBottom: "5px",
            maxHeight: "40px",
            minHeight: "40px",
            height: "40px"
          }}
        >
          <button
            className="text-uppercase admin-button"
            onClick={this.refresh}
          >
            <i className="fa fa-refresh" /> Actualizar
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.onSendMailsClick}
          >
            <i className="fa fa-envelope" />
            Envío masivo de mails
          </button>
        </div>
        <div style={{ height: "calc(100vh - 50px)" }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            getRowNodeId={data => data.id}
            modules={this.state.modules}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            statusBar={this.state.statusBar}
            paginationPageSize={this.state.paginationPageSize}
            enableRangeSelection={false}
            enableCharts={false}
            localeText={this.state.localeText}
            onGridReady={this.onGridReady}
          />
        </div>
      </div>
    );
  }
}
