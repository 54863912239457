import React, { Component, Fragment } from "react";
import CarouselGrid from "./CarouselGrid";
import GridCellImage from "../common/GridCellImage";
import { server } from "../../apis";
import $ from "jquery";
import { showPopUp, showQuestionPopUp } from "../common";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AvatarImageCropper from "react-avatar-image-cropper";

const _swalPopUp = withReactContent(Swal);
const maxsize = 1024 * 1024 * 5;

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.grid = React.createRef();
  }

  columnDefs = [
    {
      headerName: "Titulo",
      field: "title"
    },
    {
      headerName: "Imagen",
      field: "image",
      width: 185,
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: params => {
        return (
          <GridCellImage
            onClick={this.openCarouselCropper}
            src={`/images/carousel/${params.value}`}
            className={"cell-img cell-img-carousel"}
          />
        );
      }
    },
    {
      headerName: "",
      field: "delete",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button ag-grid-cell-image-carousel"
              onClick={this.handleDeleteClick}
              title="Eliminar..."
            >
              <i className="fa fa-trash ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    }
  ];

  getDataSource = async () => {
    let response = await server.get.carouselData();
    if (response.status === 200) {
      return response.data;
    }
    return [];
  };

  handleDeleteClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea eliminar la fotografía?",
      "¡Piénselo bien!<br/>Si elimina la fotografía, tendrá que practicar con la cámara.",
      null,
      "admin",
      async () => {
        let response = await server.delete.caroucel({
          id: id
        });
        if (response.status === 204) {
          this.grid.current.refresh();
          showPopUp(
            "Fotografía eliminada..!",
            "La imagen se eliminó con éxito.",
            null,
            "admin"
          );
        }
      }
    );
  };

  handleTitleChange = e => {
    this.setState({ title: $(e.target).val() });
  };

  openCarouselCropper = e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    let title = $(e.target)
      .parents(".ag-row:first")
      .find("div.ag-cell[col-id='title']")
      .html();
    this.setState({
      id: id,
      title: title
    });
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 550,
        html: (
          <div className="carousel-cropper-popup">
            <div className="form-group">
              <input
                type="text"
                className="form-control not-valid"
                id="title"
                name="title"
                defaultValue={title}
                placeholder="Ingrese un título para la fotografía."
                onFocus={e => (e.target.placeholder = "")}
                onBlur={e =>
                  (e.target.placeholder =
                    "Ingrese un título para la fotografía.")
                }
                onChange={this.handleTitleChange}
              />
            </div>
            <div
              style={{
                backgroundImage: 'url("/images/avatar-bk.png")',
                width: "510px",
                height: "352px",
                margin: "auto",
                marginTop: "10px",
                border: "1px solid #FFF",
                borderRadius: "5px"
              }}
            >
              <AvatarImageCropper
                apply={this.applyCarousel.bind(this)}
                isBack={true}
                maxsize={maxsize}
                text={"Arrastrar o hacer click aquí."}
                errorHandler={this.carrouselErrorHandler}
                loadingNode={<div style={{ color: "#FFF" }}>Cargando...</div>}
              />
            </div>
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: false,
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
        }
      });
  };

  applyCarousel = async blop => {
    await this.carouselUpload(blop);
  };

  carrouselErrorHandler = type => {
    _swalPopUp.close();
    showPopUp(
      "Ups..!",
      "Ocurrió un error al procesar la imagen.",
      null,
      "admin"
    );
  };

  carouselUpload = async blop => {
    let id = this.state.id;
    const formData = new FormData();
    const extension = blop.type.split("/")[1];
    const file = new File([blop], `${Date.now()}.${extension}`, {
      type: blop.type
    });
    formData.append("carousel", file);
    formData.append("title", this.state.title);
    let response = await server.post.carousel({
      id: id,
      form: formData
    });
    if (response.status === 200) {
      this.grid.current.refresh();
      showPopUp(
        "Fotografía actualizada..!",
        "La fotografía se actualizó con éxito.",
        null,
        "admin"
      );
    } else {
      if (response.status === 404) {
        showPopUp(
          "Ups..!",
          "No ha cargado ninguna fotografía..!",
          null,
          "admin"
        );
      } else {
        showPopUp(
          "Ups..!",
          "Ocurrio un error al procesar la imagen.",
          null,
          "admin"
        );
      }
    }
  };

  render() {
    return (
      <Fragment>
        <CarouselGrid
          columnDefs={this.columnDefs}
          getDataSource={this.getDataSource}
          ref={this.grid}
          grid={this.grid}
        />
      </Fragment>
    );
  }
}
