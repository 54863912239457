import React from "react";
import { Markup } from "interweave";
import { server } from "../../apis";
import SwalPopUp from "../common/SwalPopUp";
import $ from "jquery";

export const openPublishings = async e => {
  let jsonName = $(e.target).attr("data-json-name");
  let response = await server.get.json(jsonName);
  if (response.status === 200) {
    let title = response.data.title ? response.data.title : "";
    let content = response.data.content ? response.data.content : "";
    SwalPopUp.fire({
      showClass: {
        popup: "animated fadeInDown faster"
      },
      customClass: "swal-publishings",
      html: (
        <React.Fragment>
          <div className="popup-publishings-container">
            <Markup
              content={
                "<h1 class='publishings-title'>" +
                title +
                "</h1></br>" +
                content
              }
            />
          </div>
        </React.Fragment>
      ),
      buttonsStyling: false,
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: false
    });
  }
};

export const buildPublishingsTable = (data, jsonName, onClickCallback) => {
  //alert(jsonName);
  const onClick = onClickCallback || openPublishings;
  const length = parseInt(data.length / 3) + 1;
  let rows = [];
  let index = 0;
  for (let r = 0; r < length; r++) {
    let cols = [];
    for (let c = 0; c < 3; c++) {
      if (index < data.length) {
        let obj = data[index++];
        cols.push(
          <div className="col-lg-4" key={index}>
            <div className="publishings-preview">
              <h4>{obj.title}</h4>
              <Markup
                content={
                  "<div class='publishings-container'>" + obj.summary + "</div>"
                }
              />
              <div className="publishings-preview-footer">
                <a
                  className="read-more-link"
                  data-id={obj.id}
                  data-json-name={jsonName + "-" + obj.id}
                  onClick={onClick}
                >
                  Leer más...
                </a>
                <div className="author-title">
                  <div className="a-desc">
                    <h6>{obj.author}</h6>
                    <p>{obj.signature}</p>
                  </div>
                </div>
                <div className="publishings-date">{obj.date}</div>
              </div>
            </div>
          </div>
        );
      }
    }
    rows.push(
      <div className="row" key={r}>
        {cols}
      </div>
    );
  }
  return rows;
};
