import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../home";
import About from "../about";
import Services from "../services";
import Team from "../team";
import Contact from "../contact";
import Admin from "../admin";
import Login from "../admin/Login";
import Press from "../publishings/press";
import Books from "../publishings/books";
import NotFound from "../common/NotFound";

class App extends Component {
  state = {
    data: null
  };

  /*
  componentDidMount() {
    // Call our fetch function below once the component mounts
    this.callBackendAPI()
      .then(res => this.setState({ data: res.express }))
      .catch(err => console.log(err));
  }
  
  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch("/express_backend");
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };*/

  render() {
    return (
      <Router>
        <React.Fragment>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/services" exact component={Services} />
            <Route path="/team" exact component={Team} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/press" exact component={Press} />
            <Route path="/books" exact component={Books} />
            <Route path="/admin" exact component={Admin} />
            <Route path="/login" exact component={Login} />
            <Route path="*" component={NotFound} />
          </Switch>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
