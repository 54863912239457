import React, { Component } from "react";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import ReactSummernote from "react-summernote";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "react-summernote/dist/react-summernote.css";

export default class HtmlEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      content: this.props.content,
      options: {
        lang: "es-ES",
        height: this.props.height,
        minHeight: this.props.height,
        maxHeight: this.props.height,
        dialogsInBody: false,
        followingToolbar: false,
        airMode: false,
        toolbar: [
          ["font", ["bold", "underline", "italic", "clear"]],
          ["font", ["strikethrough", "superscript", "subscript"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["view", ["codeview"]],
          ["view", ["undo", "redo"]]
        ],
        popover: {
          image: [],
          link: [],
          air: []
        },
        disableResizeEditor: true
      }
    };
  }

  componentWillMount() {
    $(document).ready(() => {
      $(".dropdown-toggle").dropdown();
      $("i.note-icon-bold")
        .parent()
        .attr("data-original-title", "Negrita (Ctrl+B)");
      $("i.note-icon-underline")
        .parent()
        .attr("data-original-title", "Subrayar (CTRL+U)");
      $("i.note-icon-italic")
        .parent()
        .attr("data-original-title", "Itálica (CTRL+I)");
      $("i.note-icon-eraser")
        .parent()
        .attr("data-original-title", "Borrar estilos (CTRL+)");
      $("i.note-icon-unorderedlist")
        .parent()
        .attr("data-original-title", "Lista desordenada (CTRL+SHIFT+NUM7)");
      $("i.note-icon-orderedlist")
        .parent()
        .attr("data-original-title", "Lista ordenada (CTRL+SHIFT+NUM8)");
      $("i.note-icon-align-left")
        .parent()
        .attr("data-original-title", "Alinear a la izquierda (CTRL+SHIFT+L)");
      $("i.note-icon-align-center")
        .parent()
        .attr("data-original-title", "Alinear al centro (CTRL+SHIFT+E)");
      $("i.note-icon-align-right")
        .parent()
        .attr("data-original-title", "Alinear a la derecha (CTRL+SHIFT+R)");
      $("i.note-icon-align-justify")
        .parent()
        .attr("data-original-title", "Justificar (CTRL+SHIFT+J)");
      $("i.note-icon-align-outdent")
        .parent()
        .attr("data-original-title", "Sangria <- (CTRL+[)");
      $("i.note-icon-align-indent")
        .parent()
        .attr("data-original-title", "Sangria -> (CTRL+])");
      $("i.note-icon-code")
        .parent()
        .attr("data-original-title", "Ver código");
      $("i.note-icon-undo")
        .parent()
        .attr("data-original-title", "Deshacer (CTRL+Z)");
      $("i.note-icon-redo")
        .parent()
        .attr("data-original-title", "Rehacer (CTRL+Y)");
      $("span.note-icon-caret")
        .parent()
        .attr("data-original-title", "Párrafo");
      $("i.note-icon-strikethrough")
        .parent()
        .attr("data-original-title", "Tachado (CTRL+SHIFT+S)");
      $("i.note-icon-superscript")
        .parent()
        .attr("data-original-title", "Super índice");
      $("i.note-icon-subscript")
        .parent()
        .attr("data-original-title", "Subíndice");
      $(
        "span.note-icon-caret:first",
        $("button.note-btn.btn.btn-default.btn-sm.dropdown-toggle")
      ).remove();
    });
  }

  onChange = content => {
    //console.log(content);
    this.setState({
      content: content
    });
    this.props.handleContentChange(content);
  };

  render() {
    return (
      <ReactSummernote
        value={this.state.content}
        options={this.state.options}
        onChange={this.onChange}
      />
    );
  }
}
