import React, { Component } from "react";
import Menu from "../../menu";
import Footer from "../../footer";
import PressBanner from "./banner";
import PressSection from "./section";

export default class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu index={5} />
        <PressBanner />
        <PressSection />
        <Footer index={5} />
      </React.Fragment>
    );
  }
}
