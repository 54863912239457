import React, { Component } from "react";

export default class ServicesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="service-area section_gap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="main_title">
                  <h1>Áreas de práctica</h1>
                  <p>
                    El Estudio se destaca en la práctica del Derecho Penal. Sin
                    perjuicio de ello, también contamos con experiencia en
                    varias áreas del Derecho Privado. Nuestro equipo de abogados
                    posee una gran trayectoria profesional y académica.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="single-service">
                  <div className="service-thumb">
                    <img
                      className="img-fluid"
                      src="images/service/service1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="service-details">
                    <h5>Área Penal y Procesal Penal</h5>
                    <p>
                      Intervención en procesos penales en todas sus etapas como
                      defensores o patrocinantes/apoderados de la
                      víctima/querella/particular damnificado. Actuación en el
                      Fuero Nacional, Federal, Penal Contravencional y de
                      Faltas, en la Pcia. de Buenos Aires y Fuero Federal del
                      Interior del País. Recursos ordinarios y extraordinarios
                      en ámbito nacional, federal y bonaerense (Apelación,
                      Casación, Extraordinario Federal, Queja por extraordinario
                      federal denegado, Nulidad, Inconstitucionalidad e
                      Inaplicabilidad de la ley). Audiencias orales en Cámaras
                      Nacionales y Federales. Juicios Orales. Juicios por
                      Jurados.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="single-service">
                  <div className="service-thumb">
                    <img
                      className="img-fluid"
                      src="images/service/service2.jpg"
                      alt=""
                    />
                  </div>
                  <div className="service-details">
                    <h5>Áreas en particular del Derecho Penal</h5>
                    <p>
                      Derecho Penal General (Delitos contra las personas, contra
                      la integridad sexual, contra el patrimonio, contra la
                      libertad contra el honor, etc.). Derecho Penal Tributario
                      y Económico. Derecho Penal Cambiario y Aduanero. Procesos
                      de extradición. Derecho Contravencional y de Faltas.
                      Responsabilidad Penal de las Personas Jurídicas.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="single-service">
                  <div className="service-thumb">
                    <img
                      className="img-fluid"
                      src="images/service/service3.jpg"
                      alt=""
                    />
                  </div>
                  <div className="service-details">
                    <h5>Área de Derecho Privado</h5>
                    <p>
                      Asesoramiento en materia civil. Asesoramiento en materia
                      comercial. Desalojos. Divorcios. Sucesiones. Daños y
                      perjuicios en general. Accidentes de tránsito. Mala Praxis
                      médica. Convenios y resolución de conflictos. Juicios
                      ejecutivos. Seguimiento y Cobro extrajudicial y/o judicial
                      de deudas. Redacción, análisis e interpretación de
                      contratos (locaciones, compraventas, etc.).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
