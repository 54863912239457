import React, { Component, Fragment } from "react";
import SubscriptionsGrid from "./SubscriptionsGrid";
import GridCellSpan from "../common/GridCellSpan";
import { server } from "../../apis";
import $ from "jquery";
import { showPopUp, showQuestionPopUp } from "../common";

export default class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.grid = React.createRef();
  }

  columnDefs = [
    {
      headerName: "E-mail",
      field: "email"
    },
    {
      headerName: "Fecha",
      field: "date",
      width: 110
    },
    {
      headerName: "Estado",
      field: "status",
      width: 150,
      cellRendererFramework: params => {
        let text = "Pendiente";
        switch (params.value) {
          case "1":
            text = "Activa";
            break;
          case "2":
            text = "Revocado";
            break;
        }
        return (
          <GridCellSpan
            text={text}
            className={"cell-status cell-status-" + params.value}
          />
        );
      }
    },
    {
      headerName: "",
      field: "status",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: params => {
        if (params.value === "0" || params.value === "1") {
          return (
            <Fragment>
              <div
                className="ag-grid-cell-button"
                onClick={this.handleRevokeClick}
                title="Revocar..."
              >
                <i className="fa fa-ban ag-grid-icon" />
              </div>
            </Fragment>
          );
        } else return "";
      },
      width: 40
    },
    {
      headerName: "",
      field: "delete",
      sortable: false,
      filter: false,
      resizable: false,
      cellRendererFramework: () => {
        return (
          <Fragment>
            <div
              className="ag-grid-cell-button"
              onClick={this.handleDeleteClick}
              title="Eliminar..."
            >
              <i className="fa fa-trash ag-grid-icon" />
            </div>
          </Fragment>
        );
      },
      width: 40
    }
  ];

  getDataSource = async () => {
    let response = await server.get.subscription();
    if (response.status === 200) {
      return response.data;
    }
    return [];
  };

  handleRevokeClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea revocar la suscripción?",
      "¡Piénselo bien!<br/>Si rovoca la suscripción, el usuario no recibirá las noticias.",
      null,
      "admin",
      async () => {
        let response = await server.put.subscriptionRevoke({
          id: id
        });
        if (response.status === 204) {
          this.grid.current.refresh();
          showPopUp(
            "Suscripción revocada..!",
            "El usuario ya no recibirá las noticias del sitio.",
            null,
            "admin"
          );
        }
      }
    );
  };

  handleDeleteClick = async e => {
    let id = $(e.target)
      .parents(".ag-row:first")
      .attr("row-id");
    showQuestionPopUp(
      "¿Desea eliminar el registro de suscripción?",
      "¡Piénselo bien!<br/>Eliminar el registro hace que todo vuelva a foja cero.<br/>Toda acción aplicada al mail de registro se perderá.",
      null,
      "admin",
      async () => {
        let response = await server.delete.subscription({
          id: id
        });
        if (response.status === 204) {
          this.grid.current.refresh();
          showPopUp(
            "Registro de suscripción eliminado..!",
            "El usuario podrá iniciar nuevamente el pedido de suscripción.",
            null,
            "admin"
          );
        }
      }
    );
  };

  render() {
    return (
      <Fragment>
        <SubscriptionsGrid
          columnDefs={this.columnDefs}
          getDataSource={this.getDataSource}
          ref={this.grid}
          grid={this.grid}
        />
      </Fragment>
    );
  }
}
