import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const _swalPopUp = withReactContent(Swal);

export default class SwalPopUp {
  static fire(options) {
    _swalPopUp.fire(options);
  }
}

export const SwalPopUpFooter = () => (
  <span className="swal2-custom-footer">
    <strong>Pirozzo, Buigo & Mezzalira</strong>
  </span>
);

export const SwalPopUpTitle = props => (
  <span className="swal2-custom-title">{props.title}</span>
);
