import React, { Component, Fragment } from "react";
import Login from "./Login";
import { device } from "../common";

export default class SecureComponent extends Component {
  constructor(props) {
    super(props);
    if (device().mobile) {
      window.location =
        window.location.protocol + "//" + window.location.host + "/";
    }
  }

  logout = () => {
    localStorage.removeItem("ui.layout.key");
    localStorage.removeItem("ui.layout.skin");
    window.location =
      window.location.protocol + "//" + window.location.host + "/login";
  };

  isAuthenticated = () => {
    let username = localStorage.getItem("ui.layout.key");
    let password = localStorage.getItem("ui.layout.skin");
    let authenticated =
      username &&
      password &&
      !(typeof username === "undefined" || typeof password === "undefined") &&
      !(typeof username === "null" || typeof password === "null");
    return authenticated;
  };

  renderLogin = () => {
    return (
      <Fragment>
        <Login />
      </Fragment>
    );
  };
}
