import React, { Component } from "react";
import Menu from "../menu";
import Footer from "../footer";
import TeamBanner from "./banner";
import TeamSection from "./section";

export default class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu index={4} />
        <TeamBanner />
        <TeamSection />
        <Footer index={4} />
      </React.Fragment>
    );
  }
}
