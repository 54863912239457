import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import Menu from "../menu";
import Footer from "../footer";
import ContactBanner from "./banner";
import { GoogleMap } from "./map";
import { server } from "../../apis";
import { showPopUp } from "../common";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.callPhone = this.callPhone.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  callPhone(e) {
    e.preventDefault();
    window.location.href = "tel://" + e.target.getAttribute("data-phone");
  }

  async sendMessage(e) {
    let response = await server.post.sendMessage({
      name: document.getElementById("name").value,
      email: document.getElementById("email").value,
      subject: document.getElementById("subject").value,
      message: document.getElementById("message").value,
      userAgent: navigator.userAgent,
    });
    if (response.status === 201) {
      showPopUp(
        "Mensaje enviado",
        "Su consulta fue enviada con éxito,<br/> le responderemos a la brevedad ..!"
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Menu index={6} />
        <ContactBanner />
        <section className="contact_area section_gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <GoogleMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0123553231397!2d-58.388541084770395!3d-34.603849080459476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac682455555%3A0x586b8b57b68a1968!2sAv.%20Corrientes%201393%2C%20C1043%20CABA!5e0!3m2!1ses-419!2sar!4v1575592471986!5m2!1ses-419!2sar" />
                <div className="row">
                  <div className="col-lg-12">
                    <div className="contact_info">
                      <div className="info_item">
                        <i className="lnr lnr-home"></i>
                        <h6>Av. Corrientes 1393 3° F</h6>
                        <p>(C1043) CABA, Buenos Aires - Argentina</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact_info">
                  <div className="info_item">
                    <i className="lnr lnr-phone"></i>
                    <h6>
                      <a
                        className="phonecell"
                        data-phone="+5491134308533"
                        href="#"
                        onClick={this.callPhone}
                      >
                        (+54) 9 11 3430-8533
                      </a>
                    </h6>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-phone"></i>
                    <h6>
                      <a
                        className="phonecell"
                        data-phone="+5491153388220"
                        href="#"
                        onClick={this.callPhone}
                      >
                        (+54) 9 11 5338-8220
                      </a>
                    </h6>
                    <p>Lunes a viernes de 9hs a 18hs</p>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-envelope"></i>
                    <h6>
                      <a
                        href="mailto:contacto@estudiopirozzobuigo.com.ar"
                        className="mailto-contact"
                      >
                        contacto@estudiopirozzobuigo.com.ar
                      </a>
                    </h6>
                    <p>Su pregunta no molesta.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <GoogleMap src="https://maps.google.com/maps?q=Consorcio%20Belgrano%20181&t=&z=13&ie=UTF8&iwloc=&output=embed" />
                <div className="row">
                  <div className="col-lg-12">
                    <div className="contact_info">
                      <div className="info_item">
                        <i className="lnr lnr-home"></i>
                        <h6>Belgrano 181 3° A</h6>
                        <p>(B1704BUO) Ramos Mejía, Buenos Aires - Argentina</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact_info">
                  <div className="info_item">
                    <i className="lnr lnr-phone"></i>
                    <h6>
                      <a
                        className="phonecell"
                        data-phone="+5491148283899"
                        href="#"
                        onClick={this.callPhone}
                      >
                        (+54) 011 4828-3899
                      </a>
                    </h6>
                    <p>Lunes a viernes de 9hs a 18hs</p>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-envelope"></i>
                    <h6>
                      <a
                        href="mailto:contacto@estudiopirozzobuigo.com.ar"
                        className="mailto-contact"
                      >
                        contacto@estudiopirozzobuigo.com.ar
                      </a>
                    </h6>
                    <p>Su pregunta no molesta.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6"></div>
            </div>
            <div className="row contact_form">
              <div className="col-lg-12">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                  }}
                  validate={(values) => {
                    let errors = {};
                    let regMail = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
                    if (!values.name || values.name.length < 10) {
                      errors.name =
                        "Ingrese su nombre (10 caracteres como mínimo).";
                    }
                    if (!values.subject || values.subject.length < 10) {
                      errors.subject =
                        "Ingrese el motivo (10 caracteres como mínimo).";
                    }
                    if (!regMail.test(values.email)) {
                      errors.email = "El e-mail no es válido";
                    }
                    if (!values.message || values.message.length < 40) {
                      errors.message =
                        "Ingrese la consulta (40 caracteres como mínimo).";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { resetForm }) => {
                    this.sendMessage();
                    resetForm();
                  }}
                >
                  {({ errors, touched }) => (
                    <Form
                      className="row contact_form"
                      method="post"
                      id="contactForm"
                      noValidate={true}
                    >
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Ingrese su nombre completo"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) =>
                              (e.target.placeholder =
                                "Ingrese su nombre completo")
                            }
                          />
                          {errors.name && touched.name && (
                            <span className="errors active">{errors.name}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <Field
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Ingrese su e-mail"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) =>
                              (e.target.placeholder = "Ingrese su e-mail")
                            }
                          />
                          {errors.email && touched.email && (
                            <span className="errors active">
                              {errors.email}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            id="subject"
                            name="subject"
                            placeholder="Motivo de la consulta"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) =>
                              (e.target.placeholder = "Motivo de la consulta")
                            }
                          />
                          {errors.subject && touched.subject && (
                            <span className="errors active">
                              {errors.subject}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field
                            component="textarea"
                            className="form-control"
                            name="message"
                            id="message"
                            rows="1"
                            placeholder="Detalle aquí su consulta"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) =>
                              (e.target.placeholder =
                                "Detalle aquí su consulta")
                            }
                          />
                          {errors.message && touched.message && (
                            <span className="errors active">
                              {errors.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 text-right">
                        <button
                          type="submit"
                          value="submit"
                          className="primary-btn text-uppercase"
                        >
                          Enviar mensaje
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
        <Footer index={6} />
      </React.Fragment>
    );
  }
}
