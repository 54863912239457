import React, { Component, Fragment } from "react";
import { server } from "../../apis";
import { device } from "../common";
import $ from "jquery";

export default class Login extends Component {
  constructor(props) {
    super(props);
    if (device().mobile) {
      window.location =
        window.location.protocol + "//" + window.location.host + "/";
    }
  }

  login = async () => {
    if ($("#user").val().length >= 5 && $("#password").val().length >= 7) {
      let auth = {
        username: $("#user").val(),
        password: $("#password").val()
      };
      let response = await server.post.login(auth);
      if (response.status === 200) {
        localStorage.setItem("ui.layout.key", response.data.username);
        localStorage.setItem("ui.layout.skin", response.data.password);
        window.location =
          window.location.protocol + "//" + window.location.host + "/admin";
      }
    }
  };

  handleLoginClick = async () => {
    await this.login();
  };

  onKeyUp = async e => {
    if (e.which == 13) {
      await this.login();
    }
  };

  render() {
    return (
      <Fragment>
        <section className="login-area">
          <div className="container center-login">
            <div className="login-container">
              <form className="form-login">
                <h3>Administración</h3>
                <div className="form-group">
                  <label className="label-login">Usuario</label>
                  <input
                    id="user"
                    type="text"
                    className="form-control"
                    placeholder="Ingrese su usuario"
                    onKeyUp={this.onKeyUp}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group login-control">
                  <label className="label-login">Contraseña</label>
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    placeholder="Ingrese la contraseña"
                    onKeyUp={this.onKeyUp}
                    autoComplete="off"
                  />
                </div>
                <button
                  id="btnLogin"
                  type="button"
                  onClick={this.handleLoginClick}
                  className="btn btn-primary btn-block"
                >
                  Ingresar
                </button>
              </form>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
