import { Component } from "react";
import { AllModules } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";

export default class GridBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelection: null,
      modules: AllModules,
      columnDefs: this.props.columnDefs,
      rowData: null,
      rowSelection: "single",
      enableRangeSelection: false,
      paginationPageSize: 50,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      localeText: {
        // for filter panel
        page: "página",
        more: "más",
        to: "a",
        of: "de",
        next: "daNexten",
        last: "daLasten",
        first: "daFirsten",
        previous: "daPreviousen",
        loadingOoo: "Cargando...",

        // for set filter
        selectAll: "daSelect Allen",
        searchOoo: "daSearch...",
        blanks: "daBlanc",

        // for number filter and text filter
        filterOoo: "Filtro...",
        applyFilter: "Aplicar filtro...",
        equals: "Igual a",
        notEqual: "Distinto a",

        // for number filter
        lessThan: "daLessThan",
        greaterThan: "daGreaterThan",
        lessThanOrEqual: "daLessThanOrEqual",
        greaterThanOrEqual: "daGreaterThanOrEqual",
        inRange: "daInRange",

        // for text filter
        contains: "Contiene",
        notContains: "No contiene",
        startsWith: "Empieza con",
        endsWith: "Termina con",

        // filter conditions
        andCondition: "Y",
        orCondition: "O",

        // the header of the default group column
        group: "Groupo",

        // tool panel
        columns: "laColumns",
        filters: "laFilters",
        rowGroupColumns: "laPivot Cols",
        rowGroupColumnsEmptyMessage: "la drag cols to group",
        valueColumns: "laValue Cols",
        pivotMode: "laPivot-Mode",
        groups: "laGroups",
        values: "laValues",
        pivots: "laPivots",
        valueColumnsEmptyMessage: "la drag cols to aggregate",
        pivotColumnsEmptyMessage: "la drag here to pivot",
        toolPanelButton: "la tool panel",

        // other
        noRowsToShow: "No hay datos disponibles...",
        enabled: "laEnabled",

        // enterprise menu
        pinColumn: "laPin Column",
        valueAggregation: "laValue Agg",
        autosizeThiscolumn: "laAutosize Diz",
        autosizeAllColumns: "laAutsoie em All",
        groupBy: "laGroup by",
        ungroupBy: "laUnGroup by",
        resetColumns: "laReset Those Cols",
        expandAll: "laOpen-em-up",
        collapseAll: "laClose-em-up",
        toolPanel: "laTool Panelo",
        export: "laExporto",
        csvExport: "laCSV Exportp",
        excelExport: "laExcel Exporto (.xlsx)",
        excelXmlExport: "laExcel Exporto (.xml)",

        // enterprise menu (charts)
        pivotChartAndPivotMode: "laPivot Chart & Pivot Mode",
        pivotChart: "laPivot Chart",
        chartRange: "laChart Range",

        columnChart: "laColumn",
        groupedColumn: "laGrouped",
        stackedColumn: "laStacked",
        normalizedColumn: "la100% Stacked",

        barChart: "laBar",
        groupedBar: "laGrouped",
        stackedBar: "laStacked",
        normalizedBar: "la100% Stacked",

        pieChart: "laPie",
        pie: "laPie",
        doughnut: "laDoughnut",

        line: "laLine",

        xyChart: "laX Y (Scatter)",
        scatter: "laScatter",
        bubble: "laBubble",

        areaChart: "laArea",
        area: "laArea",
        stackedArea: "laStacked",
        normalizedArea: "la100% Stacked",

        // enterprise menu pinning
        pinLeft: "laPin <<",
        pinRight: "laPin >>",
        noPin: "laDontPin <>",

        // enterprise menu aggregation and status bar
        sum: "laSum",
        min: "laMin",
        max: "laMax",
        none: "laNone",
        count: "laCount",
        average: "laAverage",
        filteredRows: "laFiltered",
        selectedRows: "laSelected",
        totalRows: "laTotal Rows",
        totalAndFilteredRows: "laRows",

        // standard menu
        copy: "laCopy",
        copyWithHeaders: "laCopy Wit hHeaders",
        ctrlC: "ctrl n C",
        paste: "laPaste",
        ctrlV: "ctrl n V",

        // charts
        pivotChartTitle: "laPivot Chart",
        rangeChartTitle: "laRange Chart",
        settings: "laSettings",
        data: "laData",
        format: "laFormat",
        categories: "laCategories",
        series: "laSeries",
        xyValues: "laX Y Values",
        paired: "laPaired Mode",
        axis: "laAxis",
        color: "laColor",
        thickness: "laThickness",
        xRotation: "laX Rotation",
        yRotation: "laY Rotation",
        ticks: "laTicks",
        width: "laWidth",
        length: "laLength",
        padding: "laPadding",
        chart: "laChart",
        title: "laTitle",
        background: "laBackground",
        font: "laFont",
        top: "laTop",
        right: "laRight",
        bottom: "laBottom",
        left: "laLeft",
        labels: "laLabels",
        size: "laSize",
        minSize: "laMinimum Size",
        maxSize: "laMaximum Size",
        legend: "laLegend",
        position: "laPosition",
        markerSize: "laMarker Size",
        markerStroke: "laMarker Stroke",
        markerPadding: "laMarker Padding",
        itemPaddingX: "laItem Padding X",
        itemPaddingY: "laItem Padding Y",
        strokeWidth: "laStroke Width",
        offset: "laOffset",
        offsets: "laOffsets",
        tooltips: "laTooltips",
        callout: "laCallout",
        markers: "laMarkers",
        shadow: "laShadow",
        blur: "laBlur",
        xOffset: "laX Offset",
        yOffset: "laY Offset",
        lineWidth: "laLine Width",
        normal: "laNormal",
        bold: "laBold",
        italic: "laItalic",
        boldItalic: "laBold Italic",
        predefined: "laPredefined",
        fillOpacity: "laFill Opacity",
        strokeOpacity: "laLine Opacity",
        columnGroup: "laColumn",
        barGroup: "laBar",
        pieGroup: "laPie",
        lineGroup: "laLine",
        scatterGroup: "laScatter",
        areaGroup: "laArea",
        groupedColumnTooltip: "laGrouped",
        stackedColumnTooltip: "laStacked",
        normalizedColumnTooltip: "la100% Stacked",
        groupedBarTooltip: "laGrouped",
        stackedBarTooltip: "laStacked",
        normalizedBarTooltip: "la100% Stacked",
        pieTooltip: "laPie",
        doughnutTooltip: "laDoughnut",
        lineTooltip: "laLine",
        groupedAreaTooltip: "laGrouped",
        stackedAreaTooltip: "laStacked",
        normalizedAreaTooltip: "la100% Stacked",
        scatterTooltip: "laScatter",
        bubbleTooltip: "laBubble",
        noDataToChart: "laNo data available to be charted.",
        pivotChartRequiresPivotMode:
          "laPivot Chart requires Pivot Mode enabled."
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        suppressNavigable: true,
        cellClass: "no-border"
      }
    };
    this.onGridReady = this.onGridReady.bind(this);
    this.getDataSource = this.props.getDataSource.bind(this);
  }

  updateRow = data => {
    let rowNode = this.gridApi.getRowNode(data.id);
    rowNode.setData(data);
  };

  refresh = async () => {
    this.setState({
      rowData: await this.getDataSource()
    });
  };

  onGridReady = async params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridOptions = params.gridOptions;
    await this.refresh();
    this.gridApi.redrawRows();
    //params.api.sizeColumnsToFit();
  };

  /*
  render() {
    return <div></div>;
  }
  */
}
