import React, { Component } from "react";

export default class HomeBanner extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="banner_area">
          <div className="banner_inner d-flex align-items-center">
            <div className="container">
              <div className="banner_content text-center"></div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
