import React, { Component } from "react";
import pageNotFound_svg from "../../assets/images/notfound.svg";

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <img
            src={pageNotFound_svg}
            style={{
              width: 400,
              height: 300,
              display: "block",
              margin: "auto",
              position: "relative"
            }}
            alt=""
          />
          <center>
            <h2>
              <p
                style={{
                  color: "#000"
                }}
              >
                Volver al <a href="/">inicio</a>
              </p>
            </h2>
          </center>
        </div>
      </React.Fragment>
    );
  }
}
