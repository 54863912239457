import React from "react";
import { AgGridReact } from "ag-grid-react";
import GridBase from "../common/GridBase";
import Carousel from "../common/Carousel";
import { server } from "../../apis";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showPopUp, showQuestionPopUp } from "../common";
import $ from "jquery";
import AvatarImageCropper from "react-avatar-image-cropper";

const _swalPopUp = withReactContent(Swal);
const maxsize = 1024 * 1024 * 5;

export default class CarouselGrid extends GridBase {
  constructor(props) {
    super(props);
  }

  handlePreviewClick = e => {
    this.openPopUpCarousel();
  };

  handleNewClick = e => {
    this.setState({ title: "Sin título." });
    this.openCarouselCropper();
  };

  handleApplyClick = async e => {
    showQuestionPopUp(
      "¿Desea aplicar los cambios?",
      "¡Piénselo bien!<br/>Al aplicar los cambios, las fotografias se pondrán en línea.<br/>Verifique que no haya otras personas trabajando con las fotografias.",
      null,
      "admin",
      async () => {
        let response = await server.put.json("carousel");
        if (response.status === 201) {
          this.refresh();
          showPopUp(
            "Puesta en línea..!",
            "Las fotografias se pusieron en línea exitosamente.",
            null,
            "admin"
          );
        } else {
          if (response.status === 200) {
            showPopUp(
              "Ups..!",
              "No ha publicado ninguna fotografía..!",
              null,
              "admin"
            );
          } else {
            showPopUp(
              "Ups..!",
              "Ocurrio un error al procesar la imagen.",
              null,
              "admin"
            );
          }
        }
      }
    );
  };

  openPopUpCarousel = async data => {
    let response = await server.get.carousel();
    if (response.status === 200) {
      let images = response.data;
      _swalPopUp
        .fire({
          showClass: {
            popup: "animated fadeInDown faster"
          },
          customClass: {
            container: "swal2-container-admin",
            popup: "swal2-popup-admin",
            header: "swal2-header-admin",
            title: "swal2-title-admin",
            closeButton: "swal2-close-button-admin",
            content: "swal2-content-admin",
            input: "swal2-input-admin",
            actions: "swal2-actions-admin",
            footer: "swal2-footer-admin"
          },
          width: 600,
          html: (
            <div className="carousel-preview-popup">
              <Carousel items={images}></Carousel>
            </div>
          ),
          //footer: <SwalPopUpFooter />,
          allowOutsideClick: false,
          buttonsStyling: false,
          showCloseButton: true,
          showConfirmButton: false,
          showCancelButton: false,
          onOpen: () => {
            $(".swal2-confirm:focus").blur();
          }
        })
        .then(async result => {
          if (result.value) {
          }
        });
    }
  };

  getRowHeight = params => {
    return 104;
  };

  handleTitleChange = e => {
    this.setState({ title: $(e.target).val() });
  };

  openCarouselCropper = e => {
    _swalPopUp
      .fire({
        showClass: {
          popup: "animated fadeInDown faster"
        },
        customClass: {
          container: "swal2-container-admin",
          popup: "swal2-popup-admin",
          header: "swal2-header-admin",
          title: "swal2-title-admin",
          closeButton: "swal2-close-button-admin",
          content: "swal2-content-admin",
          input: "swal2-input-admin",
          actions: "swal2-actions-admin",
          footer: "swal2-footer-admin"
        },
        width: 550,
        html: (
          <div className="carousel-cropper-popup">
            <div className="form-group">
              <input
                type="text"
                className="form-control not-valid"
                id="title"
                name="title"
                defaultValue={""}
                placeholder="Ingrese un título para la fotografía."
                onFocus={e => (e.target.placeholder = "")}
                onBlur={e =>
                  (e.target.placeholder =
                    "Ingrese un título para la fotografía.")
                }
                onChange={this.handleTitleChange}
              />
            </div>
            <div
              style={{
                backgroundImage: 'url("/images/avatar-bk.png")',
                width: "510px",
                height: "352px",
                margin: "auto",
                marginTop: "10px",
                border: "1px solid #FFF",
                borderRadius: "5px"
              }}
            >
              <AvatarImageCropper
                apply={this.applyCarousel.bind(this)}
                isBack={true}
                maxsize={maxsize}
                text={"Arrastrar o hacer click aquí."}
                errorHandler={this.carrouselErrorHandler}
                loadingNode={<div style={{ color: "#FFF" }}>Cargando...</div>}
              />
            </div>
          </div>
        ),
        //footer: <SwalPopUpFooter />,
        allowOutsideClick: false,
        buttonsStyling: false,
        showCloseButton: true,
        showConfirmButton: false,
        onOpen: () => {
          $(".swal2-confirm:focus").blur();
        }
      })
      .then(async result => {
        if (result.value) {
        }
      });
  };

  applyCarousel = async blop => {
    await this.carouselUpload(blop);
  };

  carrouselErrorHandler = type => {
    _swalPopUp.close();
    showPopUp(
      "Ups..!",
      "Ocurrió un error al procesar la imagen.",
      null,
      "admin"
    );
  };

  carouselUpload = async blop => {
    const formData = new FormData();
    const extension = blop.type.split("/")[1];
    const file = new File([blop], `${Date.now()}.${extension}`, {
      type: blop.type
    });
    formData.append("carousel", file);
    formData.append("title", this.state.title);
    let response = await server.post.carousel({
      id: -1,
      form: formData
    });
    if (response.status === 200) {
      this.refresh();
      showPopUp(
        "Fotografía actualizada..!",
        "La fotografía se actualizó con éxito.",
        null,
        "admin"
      );
    } else {
      if (response.status === 404) {
        showPopUp(
          "Ups..!",
          "No ha cargado ninguna fotografía..!",
          null,
          "admin"
        );
      } else {
        showPopUp(
          "Ups..!",
          "Ocurrio un error al procesar la imagen.",
          null,
          "admin"
        );
      }
    }
  };

  render() {
    return (
      <div className="ag-theme-blue ag-grid-container">
        <div
          style={{
            marginBottom: "5px",
            maxHeight: "40px",
            minHeight: "40px",
            height: "40px"
          }}
        >
          <button
            className="text-uppercase admin-button"
            onClick={this.handleNewClick}
          >
            <i className="fa fa-camera" />
            Nueva fotografía
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.handlePreviewClick}
          >
            <i className="fa fa-search" />
            Vista previa
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.handleApplyClick}
          >
            <i className="fa fa-gear" />
            Aplicar
          </button>
          <button
            className="text-uppercase admin-button"
            onClick={this.refresh}
          >
            <i className="fa fa-refresh" /> Actualizar
          </button>
        </div>
        <div style={{ height: "calc(100vh - 50px)" }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            getRowHeight={this.getRowHeight}
            getRowNodeId={data => data.id}
            modules={this.state.modules}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            statusBar={this.state.statusBar}
            paginationPageSize={this.state.paginationPageSize}
            enableRangeSelection={false}
            enableCharts={false}
            localeText={this.state.localeText}
            onGridReady={this.onGridReady}
          />
        </div>
      </div>
    );
  }
}
